import React, { useEffect, useState } from 'react'
import './graph.scss';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJs } from 'chart.js/auto';
import { useImmer } from 'use-immer';
import Button from "@mui/material/Button";
import * as graphColorService from '../../Shared/Services/graphColorService/graphColor.service.js';
import * as graphService from '../../Shared/Services/graphService/graph.service'
import { checkLongDayDataAvailability } from '../../Shared/Services/tableView/tableView.service.js'


const PaperComponent = (props) => {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
const priceIntervalDur = {
    "RTM": 5,
    "RTM AVG": 60,
    "Yesterdays AVG-RTM": 60,
    "HASP": 15,
    "HASP AVG": 60,
    "Yesterdays AVG-HASP": 60,
    "FMM": 15,
    "FMM AVG": 60,
    "Yesterdays AVG-FMM": 60,
    "IFM": 60,
    "Yesterdays IFM": 60,
    "Tomorrows IFM": 60
};
const priceName = {
    "RTM": "RTM",
    "RTM AVG": "RTM-AVG",
    "Yesterdays AVG-RTM": "RTM-Yesterday-AVG",
    "HASP": "HASP",
    "HASP AVG": "HASP-AVG",
    "Yesterdays AVG-HASP": "HASP-Yesterday-AVG",
    "FMM": "FMM",
    "FMM AVG": "FMM-AVG",
    "Yesterdays AVG-FMM": "FMM-Yesterday,_AVG",
    "IFM": "IFM",
    "Yesterdays IFM": "IFM-Yesterday",
    "Tomorrows IFM": "IFM-Tomorrow"
};
let timeIntervaldata = [];
for (let i = 1; i <= 24; i++) {
    for (let j = 0; j < 12; j++) {
        timeIntervaldata.push((i * 60) + (j * 5));
    }
}
let timeIntervalLongDaydata = [];
for (let i = 1; i <= 25; i++) {
    for (let j = 0; j < 12; j++) {
        timeIntervalLongDaydata.push((i * 60) + (j * 5));
    }
}

export default function Graph(props) {
    const { open, closeGraph, openRtmGraph, handleRtmGraph, settingsCloseFlag } = props;
    const [graphData, setGraphData] = useImmer({ labels: [], datasets: [] });
    const [rtmGraphData, setRtmGraphData] = useImmer({ labels: [], datasets: [] });
    const [nodeName, setNodeName] = useState("");
    const [graphStyle, setGrapStyle] = useImmer({}); 
    const [rtmGraphStyle, setRtmGrapStyle] = useImmer({});    

    function getNodeAndColor() {
        graphColorService.getGraphLegendConfigurationTable().then((colorRes) => {
            graphColorService.getGraphNodeTable().then((nodeRes) => {
                checkLongDayDataAvailability({ tableView: false, nodeName: nodeRes.data[0].nodeName }).then((response) => {
                    if (response.data.length !== 0 && response.data[0].status === "True") {
                        loadGraph(colorRes.data, nodeRes.data[0], timeIntervalLongDaydata);
                    }
                    else {
                        loadGraph(colorRes.data, nodeRes.data[0], timeIntervaldata);
                    }
                    setNodeName(nodeRes.data[0].aliasName);
                });
            });
        });
    }

    function loadGraph(colorDetails, nodeDetails, timeInterval) {
        const graphStyleTemp = {
            responsive: true,
            scales: {
                x: {
                    border: {
                        color: 'black'
                    },
                    ticks: {
                        callback: (index) => {
                            let tick = timeInterval[index] / 60;
                            if (parseInt(tick) === parseFloat(tick)) return (tick);
                        }
                    },
                    title: {
                        display: true,
                        text: 'Hour Ending'
                    }
                },
                y: {
                    border: {
                        color: 'black'
                    },
                    title: {
                        display: true,
                        text: 'LMP $/MW'
                    }
                }
            },
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true,
                        pointStyle: 'rect'
                    },
                    onClick: () => { }
                },
                tooltip: {
                    enabled: false
                }
            }
        };
        setGrapStyle(graphStyleTemp);

        const rtmGraphStyleTemp = {
            responsive: true,
            scales: {
                x: {
                    border: {
                        color: 'black'
                    },
                    ticks: {
                        callback: (index) => {
                            let tick = timeInterval[index] / 60;
                            if (parseInt(tick) === parseFloat(tick)) return (tick);
                        }
                    },
                    title: {
                        display: true,
                        text: 'Hour Ending'
                    }
                },
                y: {
                    border: {
                        color: 'black'
                    },
                    title: {
                        display: true,
                        text: 'LMP $/MW'
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                }
            }
        };
        setRtmGrapStyle(rtmGraphStyleTemp);

        graphService.getGraphViewData({ nodeLocName: nodeDetails.nodeName }).then((response) => {
            let gData = {
                labels: timeInterval,
                datasets: []
            };
            colorDetails.forEach((e, i) => {
                let data = [];
                response.data.children.forEach((eRes) => {
                    if (priceName[e.marketTypeName] === eRes.priceName) data = eRes.children;
                });
                let obj = {
                    label: e.marketTypeName,
                    data: data.map((eD) => ({ x: ((eD.hoursNo * 60) + ((eD.intervalValue === "0") || (eD.intervalValue === "100") || (eD.intervalValue === "1000") ? 0 : ((parseInt(eD.intervalValue) - 1) * priceIntervalDur[e.marketTypeName]))), y: eD.amount })),
                    borderColor: e.colorCode,
                    backgroundColor: e.colorCode,
                    fill: false,
                    tension: 0.1,
                    pointStyle: false,
                    stepped: (e.marketTypeName !== "RTM") ? true : false
                }
                if (e.showInGraph === true) gData.datasets.push(obj);
            });
            setGraphData(gData);

            let gDataRtm = {
                labels: timeInterval,
                datasets: []
            };
            let rtmColor = "";
            let showInGraph = true;
            colorDetails.forEach((e) => {
                if (e.marketTypeName === "RTM") {
                    rtmColor = e.colorCode;
                    showInGraph = e.showInGraph;
                }
            });
            let rtmData = [];
            response.data.children.forEach((eRes) => {
                if (priceName["RTM"] === eRes.priceName) rtmData = eRes.children;
            });
            const rtmObj = {
                label: 'RTM',
                data: rtmData.map((eD) => ({ x: ((eD.hoursNo * 60) + ((parseInt(eD.intervalValue) - 1) * priceIntervalDur["RTM"])), y: eD.amount })),
                borderColor: rtmColor,
                backgroundColor: rtmColor,
                fill: false,
                tension: 0.1,
                pointStyle: false
            }
            if (showInGraph) gDataRtm.datasets.push(rtmObj);
            setRtmGraphData(gDataRtm);
        });
    }

    useEffect(() => {
        let timeId = setInterval(() => {
            getNodeAndColor();
        }, 300000);
        return () => { clearInterval(timeId) }
    }, []);

    useEffect(() => {
        getNodeAndColor();
    }, [settingsCloseFlag]);

    return (
        <>
            <Dialog sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "100%",
                        height: "100%",
                        maxHeight: "100%"
                    },
                },
            }} open={open}>
                <DialogTitle>
                    <div className="header">
                        <div>Graph</div>
                        <div style={{ fontWeight: "bold" }}>{nodeName}</div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Button variant="outlined" color="success" size="medium" style={{ margin: "0 5px", height: "25px" }} onClick={() => { handleRtmGraph(true) }}>View RTM Graph</Button>
                            <button onClick={() => { setGraphData({ labels: [], datasets: [] }); getNodeAndColor() }} style={{ marginRight: "5px" }}><RefreshIcon /></button>
                            <button onClick={() => { closeGraph() }}><CloseIcon /></button>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent style={{ display: "flex", justifyContent: "center" }}>
                    <div className='graph_container'>
                        <Line data={graphData} options={graphStyle} />
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "45%",
                        height: "100%",
                        maxHeight: "60%"
                    },
                },
            }} PaperComponent={PaperComponent} open={openRtmGraph}>
                <DialogTitle id="draggable-dialog-title" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ fontWeight: "bold" }}>{nodeName} RTM</div>
                    <button onClick={() => { handleRtmGraph(false) }}><CloseIcon /></button>
                </DialogTitle>
                <div className='graph_container_rtm' style={{ display: "flex", justifyContent: "center" }}>
                    <Line data={rtmGraphData} options={rtmGraphStyle} />
                </div>
            </Dialog>
        </>
    )
}
