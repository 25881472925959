import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import "./adminFunctionAppStatus.scss";
import moment from 'moment';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';


export default function AdminFunctStatus(props){
    const {data}=props
    let error=false
    console.log(data)
    if(typeof(data)==="string"){
        error=true
    }

    return <Dialog open={props.open} maxWidth="lg">
                <DialogTitle> 
                    <p style={{fontSize:'16px', width:'30vw'}}>Function App Status</p> 
                    <button style={{position:'absolute', top:'30px', right:'24px'}} className="closeButton" onClick={() => { props.close() }}><CloseIcon /></button>
                </DialogTitle>
                <DialogContent>
                    {error?<div style={{margin: "auto","text-align": "center","font-size": "16px"}}>{data}</div>:data?<TableContainer className="table-container1">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{textAlign:"center",padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>TradeDateTime</TableCell>
                            <TableCell sx={{width:"100px",textAlign:"center",padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>Status</TableCell>                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(d=>{
                            return <TableRow key={d.tradeDateTime}>
                                        <TableCell>{moment(d.tradeDateTime).format('MM-DD-yyyy') + " HE" + (Number(moment(d.tradeDateTime).format('HH:mm').split(":")[0])+1)}</TableCell>
                                        <TableCell sx={{color:d.status.toLowerCase()=="success"?"green":"red"}}>{d.status}</TableCell>
                                    </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>:<div style={{margin: "auto","text-align": "center","font-size": "16px"}}>In Progress ...</div> }
                </DialogContent>
            </Dialog>
}