import axios from 'axios';

const apiURL=process.env.REACT_APP_BASE_URL

export function getFunctionAppDetails(){
    return axios.get(apiURL + "FunctionAppDetails/GetFunctionAppDetails");
}

export function triggerFunctionApp(req){
    return axios.post(apiURL + "FunctionApp/TriggerFunctionApp",req);
}
export function getStatus(url){
    return axios.get(url);
}