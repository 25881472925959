import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import Button from "@mui/material/Button";
import { useImmer } from 'use-immer';
import * as headerService from '../../Shared/Services/headerColorService/headerColor.service.js';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import './header_color_configuration.scss';
import * as headerColorService from '../../Shared/Services/headerColorService/headerColor.service'
import { Colors } from 'chart.js';
import {colorPalette,getColorName} from '../colorPaletteConfiguration/colorPaletteConfiguration.js'


export default function HeaderColorConfig(props) {
  const userId = "admin"
  const [isDisabled, setIsDisabled] = useState(true);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [selectedNode, setSelectedNode] = useImmer(
    {
      nodeName: "",
      color: "#000000",
      textColor: "#000000"
    }
  );
  const [isSnackOpen, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const [warningDialog, setWarningDailog] = useState(false);

  const [sortType, setSortType] = useState("asc");
  const [sortActive, setSortActive] = useState(false);
  function sortFunction() {
    setSortActive(true)
    setSortType(sortType === "asc" ? "desc" : "asc");
    let localSortType = sortType === "asc" ? "desc" : "asc";
    let obj = tableData.sort((a, b) => {
      return a.nodeName > b.nodeName ? 1 : -1
    })
    if (localSortType === "desc") {
      obj.reverse()
    }
    setTableData(obj);
  }

  useEffect(() => {
    headerColorService.getHeaderColorDetails().then((response) => {
      let arr = [
        {
          nodeName: "RTM",
          color: response.data[0].headerRtmColor,
          textColor: response.data[0].textRtmColor
        },
        {
          nodeName: "HASP",
          color: response.data[0].headerHaspColor,
          textColor: response.data[0].textHaspColor
        },
        {
          nodeName: "IFM",
          color: response.data[0].headerIfmColor,
          textColor: response.data[0].textIfmColor
        },
        {
          nodeName: "FMM",
          color: response.data[0].headerFmmColor,
          textColor: response.data[0].textFmmColor
        }
      ]
      setTableData(arr);
    });
  }, [reloadTrigger])

  function onClickUpdate() {
    let updateObj = {
      headerColorId: 1,
      headerRtmColor: (selectedNode.nodeName === "RTM") ? selectedNode.color : tableData[0].color,
      headerHaspColor: (selectedNode.nodeName === "HASP") ? selectedNode.color : tableData[1].color,
      headerIfmColor: (selectedNode.nodeName === "IFM") ? selectedNode.color : tableData[2].color,
      headerFmmColor: (selectedNode.nodeName === "FMM") ? selectedNode.color : tableData[3].color,
      textRtmColor: (selectedNode.nodeName === "RTM") ? selectedNode.textColor : tableData[0].textColor,
      textHaspColor: (selectedNode.nodeName === "HASP") ? selectedNode.textColor : tableData[1].textColor,
      textIfmColor: (selectedNode.nodeName === "IFM") ? selectedNode.textColor : tableData[2].textColor,
      textFmmColor: (selectedNode.nodeName === "FMM") ? selectedNode.textColor : tableData[3].textColor,
      lastUpdatedBy: userId,
      lastUpdatedOn: "2023-12-21T11:38:00.508Z"
    };
    headerColorService.updatHeaderColorDetails(updateObj).then((response) => {
      setSnackMessage(response.data);
      setSnack(true);
      setReloadTrigger(reloadTrigger + 1);
    });
  }

  function onClickOk() {
    setIsDisabled(true);
    setSelectedNode({ nodeName: "", color: "#000000", textColor: "#000000" });
    setWarningDailog(false);
  }

 

  let action = (<IconButton color="inherit" onClick={() => { setSnack(false) }}><CloseIcon /></IconButton>)

  return (
    <>
      <div className='main_container'>
        <div className='upper_sec'>
          <input type="text" disabled style={{ width: 24 + '%', height: 25 + 'px', textAlign: 'center' }} value={selectedNode.nodeName}></input>
          <div style={{ width: '24%', height: '30px' }}>
            <input type="color" disabled={isDisabled} list="colors" style={{ width: 50 + '%', height: 30 + 'px' }} value={selectedNode.color} onChange={(e) => { setSelectedNode((d) => { d.color = e.target.value }) }}></input>
            <datalist id="colors">
              {colorPalette.map((color) => {
                return <option value={"#" + color.HEX}></option>
              })}
            </datalist>
            <div style={{ float: 'right', width: 50 + '%', height: 30 + 'px', textAlign: 'center', boxSizing: 'border-box', border: '1px solid buttonborder', lineHeight: '30px' }}>{getColorName(selectedNode.color.toUpperCase())?getColorName(selectedNode.color.toUpperCase()).Colorname:selectedNode.color.toUpperCase()}</div>
          </div>
          <div style={{ width: '24%', height: '30px' }}>
            <input type="color" disabled={isDisabled} list="colors" style={{ width: 50 + '%', height: 30 + 'px' }} value={selectedNode.textColor} onChange={(e) => { setSelectedNode((d) => { d.textColor = e.target.value }) }}></input>
            <datalist id="colors">
              {colorPalette.map((color) => {
                return <option value={"#" + color.HEX}></option>
              })}
            </datalist>
            <div style={{ float: 'right', width: 50 + '%', height: 30 + 'px', textAlign: 'center', lineHeight: '30px', boxSizing: 'border-box', border: '1px solid buttonborder' }}>{getColorName(selectedNode.textColor.toUpperCase())?getColorName(selectedNode.textColor.toUpperCase()).Colorname:selectedNode.textColor.toUpperCase()}</div>
          </div>
          <Button variant="contained" color="success" style={{ width: 12 + '%', height: 30 + 'px' }} disabled={isDisabled} onClick={() => { onClickUpdate(); setIsDisabled(true); setSelectedNode({ nodeName: "", color: "#000000", textColor: "#000000" });}} >Update</Button>
          <Button color="secondary" variant="contained" style={{ width: 12 + '%', height: 30 + 'px' }} disabled={isDisabled} onClick={() => { setWarningDailog(true) }}>Cancel</Button>
        </div>
        <div style={{ paddingTop: 15 + 'px' }}>
          <TableContainer>
            <Table stickyHeader sx={{ width: 100 + '%' }}>
              <TableHead sx={{ width: 100 + '%' }} aria-label="simple table">
                <TableRow>
                  {/* <TableCell style={{ width: 25 + '%', backgroundColor: "#6DC9C9", height: 25 + 'px', fontSize: 15 + 'px', textAlign: "center", fontWeight: "bold" }}>Name</TableCell> */}
                  <TableCell style={{ width: 25 + '%', backgroundColor: "#6DC9C9", height: 25 + 'px', fontSize: 15 + 'px', textAlign: "center", fontWeight: "bold" }}>
                    <TableSortLabel active={sortActive} direction={sortType} onClick={() => { sortFunction(); }}>
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: 25 + '%', backgroundColor: "#6DC9C9", height: 25 + 'px', fontSize: 15 + 'px', textAlign: "center", fontWeight: "bold" }}>Color</TableCell>
                  <TableCell style={{ width: 25 + '%', backgroundColor: "#6DC9C9", height: 25 + 'px', fontSize: 15 + 'px', textAlign: "center", fontWeight: "bold" }}>Text Color</TableCell>
                  <TableCell style={{ width: 25 + '%', backgroundColor: "#6DC9C9", height: 25 + 'px', fontSize: 15 + 'px', textAlign: "center", fontWeight: "bold" }}>Update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((e) => (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }}>{e.nodeName}</TableCell>
                    <TableCell><input type="color" disabled value={e.color} style={{ width: 40 + '%', height: 30 + 'px' }}></input><div style={{ float: 'right', width: 60 + '%', height: 30 + 'px', textAlign: 'center', lineHeight: '30px' }}>{getColorName(e.color.toUpperCase())?getColorName(e.color.toUpperCase()).Colorname:e.color.toUpperCase()}</div></TableCell>
                    <TableCell><input type="color" disabled value={e.textColor} style={{ width: 40 + '%', height: 30 + 'px' }}></input><div style={{ float: 'right', width: 60 + '%', height: 30 + 'px', textAlign: 'center', lineHeight: '30px' }}>{getColorName(e.textColor.toUpperCase())?getColorName(e.textColor.toUpperCase()).Colorname:e.textColor.toUpperCase()}</div></TableCell>
                    <TableCell><Button variant="contained" color="success" style={{ width: 100 + '%', height: 30 + 'px' }} onClick={() => { setIsDisabled(false); setSelectedNode(e); }}>Update</Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} action={action} open={isSnackOpen} message={snackMessage} onClose={() => { setSnack(false) }} />

      {warningDialog ?
        <Dialog sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "50%",
              maxWidth: "350px",  // Set your width here
              height: "50%",
              maxHeight: "150px"
            }
          }
        }} open={warningDialog}>
          <DialogTitle>
            <div className="header">
              <div className="divInline">Confirmation</div>
              <button className="closeButton" onClick={() => { setWarningDailog(false) }}><CloseIcon /></button>
            </div>
          </DialogTitle>
          <DialogContent>
            <div>Are you sure you want to cancel the update process?</div>
          </DialogContent>
          <DialogActions>
            <button data-testid="okButton" onClick={() => { onClickOk() }} style={{ float: "right", width: "50px", height: "20px" }} >OK</button>
            <button data-testid="reject" onClick={() => { setWarningDailog(false) }} style={{ float: "right", width: "50px", height: "20px" }} >Cancel</button>
          </DialogActions>
        </Dialog> : null}
    </>
  )
}