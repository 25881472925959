import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useEffect } from 'react';
import axios from 'axios';

function LogIn(props){
    const { instance, accounts, inProgress } = useMsal()
    const logUserDetails = () => {
      return axios.get('Authentication/IsAuthenticated').then((res) => console.log(`Logged in as ${res.data.userName}`))
    } 

    useEffect(()=>{
      if(accounts.length>0 && inProgress===InteractionStatus.None){
        instance.acquireTokenSilent({
          scopes: [process.env.REACT_APP_SCOPE],
          account: accounts[0]
        }).then(res=>{
          props.setter(true)
          localStorage.setItem('token', res.accessToken)
          localStorage.setItem("user",JSON.stringify(accounts[0]))
          logUserDetails()
        })
      }
    },[ instance, accounts, inProgress ]) // eslint-disable-line react-hooks/exhaustive-deps
    
    if(accounts.length===0 && inProgress===InteractionStatus.None){
      instance.loginRedirect({
       scopes: [process.env.REACT_APP_SCOPE]
      })
    }
}

export default LogIn;