import React,{ useState,useEffect } from 'react';
import {useImmer} from 'use-immer';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import {Button,IconButton,Snackbar,Table,TableBody,TableSortLabel,TableHead,TableRow,TableCell} from '@mui/material'
import './alarm_configuration.scss'
import * as nodeService from '../../Shared/Services/nodeConfiguration/node.configuration.service.js'
import * as alarmConfigService from '../../Shared/Services/alarmConfiguration/alarmConfigurati.service.js';

export default function AlarmConfig(props){
    let tableHeader=[
        {
            id:"alrmUsrPrfLocNam",            
            label:"Node Name",
            width:"16%",
            type:"string"
        },
        {
            id:"alrmUsrPrfMktNam",            
            label:"Market Type",
            width:"10%",
            type:"string"
            
        },
        {
            id:"alrmUsrPrfUThrshld1Num",            
            label:"Alarm +1 ($)",
            width:"14%",
            type:"number"
        },
        {
            id:"alrmUsrPrfUThrshld2Num",            
            label:"Alarm +2 ($)",
            width:"14%",
            type:"number"
        },
        {
            id:"alrmUsrPrfLThrshld1Num",            
            label:"Alarm -1 ($)",
            width:"14%",
            type:"number"
        },
        {
            id:"alrmUsrPrfLThrshld2Num",            
            label:"Alarm -2 ($)",
            width:"14%",
            type:"number"
        }
    ]
    const [orderBy,setOrderBy]=useState("")
    const [sortType,setSortType]=useState("")
    const [addState,setAddState]=useState(true)
    const [alarmForm,setAlarmForm]=useImmer({
        alrmUsrPrfSysId: 0,
        alrmUsrPrfLocNam:"",
        alrmUsrPrfMktNam:"",
        alrmUsrPrfUThrshld1Num:null,
        alrmUsrPrfUThrshld2Num:null,
        alrmUsrPrfLThrshld1Num:null,
        alrmUsrPrfLThrshld2Num:null,
    })
    const [prevAlarmForm,setPrevAlarmForm]=useImmer({
        alrmUsrPrfSysId: 0,
        alrmUsrPrfLocNam:"",
        alrmUsrPrfMktNam:"",
        alrmUsrPrfUThrshld1Num:null,
        alrmUsrPrfUThrshld2Num:null,
        alrmUsrPrfLThrshld1Num:null,
        alrmUsrPrfLThrshld2Num:null,
    })
    const [nodeDropdown,setNodeDropdown]=useState([])
    const [priceType,setPriceType]=useState([])
    const [tableData,setTableData]=useState([])
    const dollarIcon=()=>(<IconButton sx={{padding:"4px"}} color="inherit"><AttachMoneyIcon /></IconButton>)
    let userId={usrId:JSON.parse(localStorage.getItem("user")).username}    

    
    useEffect(()=>{
        nodeService.getAllNodeConfigurationDetails().then((result)=>{
            setNodeDropdown(result.data)
            setAlarmForm(draft=>{draft.alrmUsrPrfLocNam=result.data[0].aliasname})
        })
        nodeService.getHeaderMarketType().then((result)=>{
            setPriceType(result.data)
            setAlarmForm(draft=>{draft.alrmUsrPrfMktNam=result.data[0].descprition})
        })
        fetchAlarmConfig()
    },[])

    function validate(){
        console.log(alarmForm)
        if(addState){
            let searchData=tableData.find((config)=>{
                return config.alrmUsrPrfLocNam===alarmForm.alrmUsrPrfLocNam && config.alrmUsrPrfMktNam===alarmForm.alrmUsrPrfMktNam;
            })
            if(searchData){
                alert("Selected combination of "+alarmForm.alrmUsrPrfLocNam+" and "+alarmForm.alrmUsrPrfMktNam+" is already configured")
                return false;
            }
        }
        if(alarmForm.alrmUsrPrfUThrshld1Num===null || alarmForm.alrmUsrPrfUThrshld2Num===null || alarmForm.alrmUsrPrfLThrshld1Num===null || alarmForm.alrmUsrPrfLThrshld2Num===null){
            alert("Please enter all alarm threshold values")
            return false
        }
        else if(alarmForm.alrmUsrPrfUThrshld1Num>=alarmForm.alrmUsrPrfUThrshld2Num){
            alert("Alarm +2 should be greater than Alarm +1")
            return false
        }
        else if(alarmForm.alrmUsrPrfLThrshld1Num<=alarmForm.alrmUsrPrfLThrshld2Num){
            alert("Alarm -1 should be greater than Alarm -2")
            return false
        }
        else if(alarmForm.alrmUsrPrfUThrshld1Num<=alarmForm.alrmUsrPrfLThrshld1Num){
            alert("Alarm +1 should be greater than Alarm -1")
            return false
        }
        return true; 
    }
    function addAlarm(){
        if(!validate()){
            return
        }
        let data={...alarmForm,...userId}
        data["createdDt"]="2023-12-20T11:51:07.660Z"
        data["createdBy"]=userId.usrId
        alarmConfigService.addAlarmConfig(data).then((result)=>{
            //SnackBar
            alert("New Alarm configuration is added Successfully.")
            fetchAlarmConfig()
            clearForm()
        })
    }

    function fetchAlarmConfig(){
        alarmConfigService.getAlarmConfig(userId).then((result)=>{
            if(orderBy===""){
                setTableData(result.data)
            }
            else{
                let obj=nodeService.sortObjectArray(result.data,orderBy,sortType)
                setTableData(obj)
            }
        })
    }
    function clearForm(){
        setAlarmForm({
            alrmUsrPrfSysId: 0,
            alrmUsrPrfLocNam:"",
            alrmUsrPrfMktNam:"",
            alrmUsrPrfUThrshld1Num:null,
            alrmUsrPrfUThrshld2Num:null,
            alrmUsrPrfLThrshld1Num:null,
            alrmUsrPrfLThrshld2Num:null,
        })
        setAlarmForm(draft=>{draft.alrmUsrPrfLocNam=nodeDropdown[0].aliasname})
        setAlarmForm(draft=>{draft.alrmUsrPrfMktNam=priceType[0].descprition})
        setAddState(true)
    }


    function loadData(id){
        setAddState(false)
        let data={"alrmUsrPrfSysId": id}
        alarmConfigService.getIndividualAlarmConfig(data).then((result)=>{
            setAlarmForm(result.data[0])
            setPrevAlarmForm(result.data[0])
        })
    }

    function deletConfig(id){
        if(!window.confirm("Are you sure you want to delete the configuration?")){
            return;
        }
        let data={"alrmUsrPrfSysId": id}
        if(id===alarmForm.alrmUsrPrfSysId){
            clearForm()
        }
        alarmConfigService.deleteAlarmConfig(data).then((result)=>{
            //snack bar
            alert("Configuration deleted successfully")
            fetchAlarmConfig()
        })
    }
    function cancelUpdate(){
        if(JSON.stringify(alarmForm)=== JSON.stringify(prevAlarmForm)){
            clearForm()
        }
        else{
            if(window.confirm("There is unsaved data. Are you sure you  want to continue?")){
                clearForm()
            }
        }
    }
    function updateAlarmConfig(){
        if(!validate()){
            return
        }
        let data={...alarmForm,...userId}
        data["createdBy"]=userId.usrId
        data["modifiedBy"]=userId.usrId
        alarmConfigService.updateAlarmConfig(data).then((result)=>{
            clearForm()
            alert("Configuration Updated successfully")            
            fetchAlarmConfig()
        })
    }
   

    function sortFunction(id,type){
        let localSortType="asc";
        if(orderBy===id){
           localSortType=sortType==="asc"?"desc":"asc";
            setSortType(sortType==="asc"?"desc":"asc");
        }else{
            setSortType("asc");
            setOrderBy(id);
        }
        let obj=nodeService.sortObjectArrayFinal(tableData,id,localSortType,type)
        setTableData(obj)
    }


    return (
            <>
                <div className="alarmformStyling">
                    <select data-testid="nodeName" disabled={!addState} name="nodeName" value={alarmForm.alrmUsrPrfLocNam} onChange={(e)=>{setAlarmForm((form)=>{form.alrmUsrPrfLocNam=e.target.value})}} >
                        {nodeDropdown.map((node)=>{
                            return <option value={node.aliasname}>{node.aliasname}</option>
                        })}
                    </select>
                    <select data-testid="marketType" disabled={!addState} name="marketType" value={alarmForm.alrmUsrPrfMktNam} onChange={(e)=>{setAlarmForm((form)=>{form.alrmUsrPrfMktNam=e.target.value})}}  className="marketType">
                        {priceType.map((type)=>{
                            return <option value={type.descprition}>{type.descprition}</option>
                        })}
                    </select>
                    <div className="pricesGrid"><input data-testid="NumberInput1" type="number" className="prices input" value={alarmForm.alrmUsrPrfUThrshld1Num!=null?alarmForm.alrmUsrPrfUThrshld1Num:""}  onChange={(e)=>{setAlarmForm((form)=>{form.alrmUsrPrfUThrshld1Num=!isNaN(parseFloat(e.target.value,10))?parseFloat(e.target.value,10):null})}}/>{dollarIcon()}</div>
                    <div className="pricesGrid"><input data-testid="NumberInput2" type="number" className="prices input" value={alarmForm.alrmUsrPrfUThrshld2Num!=null?alarmForm.alrmUsrPrfUThrshld2Num:""}  onChange={(e)=>{setAlarmForm((form)=>{form.alrmUsrPrfUThrshld2Num=!isNaN(parseFloat(e.target.value,10))?parseFloat(e.target.value,10):null})}}/>{dollarIcon()}</div>
                    <div className="pricesGrid"><input data-testid="NumberInput3" type="number" className="prices input" value={alarmForm.alrmUsrPrfLThrshld1Num!=null?alarmForm.alrmUsrPrfLThrshld1Num:""}  onChange={(e)=>{setAlarmForm((form)=>{form.alrmUsrPrfLThrshld1Num=!isNaN(parseFloat(e.target.value,10))?parseFloat(e.target.value,10):null})}}/>{dollarIcon()}</div>
                    <div className="pricesGrid"><input data-testid="NumberInput4" type="number" className="prices input" value={alarmForm.alrmUsrPrfLThrshld2Num!=null?alarmForm.alrmUsrPrfLThrshld2Num:""}  onChange={(e)=>{setAlarmForm((form)=>{form.alrmUsrPrfLThrshld2Num=!isNaN(parseFloat(e.target.value,10))?parseFloat(e.target.value,10):null})}}/>{dollarIcon()}</div>
                    {
                        addState?<Button data-testid="AddFormButton" variant="contained" color="success" onClick={addAlarm}>Add</Button>:
                        <div className="updateState">
                            <Button onClick={updateAlarmConfig} data-testid="updateForm" variant="contained" color="success" className="updateFormButon">Update</Button>
                            <Button data-testid="cancel" onClick={cancelUpdate} variant="contained" color="secondary" className="cancelFormButton">Cancel</Button>
                        </div>
                    }
                </div>
            <div className="tableDivAlarm">
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableHeader.map((head)=>{
                                return <TableCell key={head.id} width={head.width} id={head.id} sx={{textAlign:"center",width: head.width,padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>
                                        <TableSortLabel
                                        active={orderBy===head.id}
                                        direction={sortType}
                                        onClick={()=>{sortFunction(head.id,head.type)}}>
                                            {head.label}
                                        </TableSortLabel>
                                </TableCell>
                            })}
                            <TableCell sx={{textAlign:"center",width: "8%",fontWeight: "bold",backgroundColor:"#6DC9C9"}} >Update</TableCell>
                            <TableCell sx={{textAlign:"center",width: "8%",fontWeight: "bold",backgroundColor:"#6DC9C9"}} >Delete</TableCell>
                        </TableRow>
                        
                    </TableHead>
                    <TableBody>
                        {tableData.map((alconfig)=>{
                            return <TableRow data-testid="alarmConfigData" >
                                        {tableHeader.map(header=>{
                                            return <TableCell key={header.id}>
                                                    {alconfig[header.id]}
                                            </TableCell>
                                        })}
                                        <TableCell><Button data-testid="update" onClick={()=>loadData(alconfig["alrmUsrPrfSysId"])} size="small" variant="contained" color="success" >Update</Button></TableCell>
                                        <TableCell><Button data-testid="DeleteButton" onClick={()=>{deletConfig(alconfig["alrmUsrPrfSysId"])}} size="small" variant="contained" color="secondary" >Delete</Button></TableCell>
                                    </TableRow>
                        })}

                    </TableBody>
                </Table>
            </div>
            </>
            )
}