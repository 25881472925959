import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CloseIcon from '@mui/icons-material/Close';
import NodeConfiguration from '../node_configuration/node_configuration.js'
import { useTheme } from '@mui/material/styles';
import React,{ useState } from 'react';
import './settings.scss'
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import AlarmConfig from '../alarm_configuration/alarm_configuration.js'
import AlarmColor from '../alarm_color/alarm_color.js'
import GraphNode from '../graph_configuration/graph_configurtion.js';
import HeaderColorConfig from '../header_color_configuration/header_color_configuration.js'

const PaperComponent = (props) => {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

export default function Settings(props){
    const { closeHandler, open, reloadFunction, settingsCloseFlag } = props;
    const [tabIndex,setIndex]=useState('1');
    const theme = useTheme();

    const tabChange=(event,index)=>{
        setIndex(index);
    }

    return (
        <Dialog data-testid="settings" sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "95%",
                maxWidth: "90%",  // Set your width here
                height: "95%",
                maxHeight:"90%"
              },
            },
          }}  
          PaperComponent={PaperComponent}
          open={open}
          >
            <DialogTitle id="draggable-dialog-title">
                <div className="header">
                    <div className="divInline">Configuration</div>
                    <button data-testid="closeIcon" className="closeButton" onClick={()=>{closeHandler(); reloadFunction(settingsCloseFlag + 1);}}><CloseIcon/></button>                
                </div>
                </DialogTitle>
            <DialogContent>
          
            <Box sx={{ width: '100%'}}>
                <TabContext value={tabIndex}>
                    <Box >
                    <TabList onChange={tabChange} aria-label="lab API tabs example">
                        <Tab data-testid="Tabs"  label="Alarm Configuration" sx={{"fontWeight" : "bold"}} value="1" />
                        <Tab data-testid="Tabs"  label="Node Configuration" sx={{"fontWeight" : "bold"}} value="2" />
                        <Tab data-testid="Tabs"  label="Graph Node" sx={{"fontWeight" : "bold"}} value="3" />
                        <Tab data-testid="Tabs"  label="Alarm Color Configuration" sx={{"fontWeight" : "bold"}} value="4" />
                        <Tab data-testid="Tabs"  label="Header Color Configuration" sx={{"fontWeight" : "bold"}} value="5" />
                    </TabList>
                    </Box>
                    <div style={{"display":(tabIndex==1?"block":"none"),"padding":"24px"}}><AlarmConfig/></div> 
                    <div style={{"display":(tabIndex==2?"block":"none"),"padding":"24px"}}><NodeConfiguration /></div>
                    <div style={{"display":(tabIndex==3?"block":"none"),"padding":"24px"}}><GraphNode /></div>
                    <div style={{"display":(tabIndex==4?"block":"none"),"padding":"24px"}}><AlarmColor/></div> 
                    <div style={{"display":(tabIndex==5?"block":"none"),"padding":"24px"}}><HeaderColorConfig /></div>                   
                </TabContext>
            </Box>
            </DialogContent>
        </Dialog>
    )
}