import ChecklistIcon from '@mui/icons-material/Checklist';
import './node_configuration.scss';
import React,{ useState,useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close';
import AddRemove from '../add_Remove/add_Remove.js'
import HeaderMarket from '../header.market.details/header.market.details.js';
import {useImmer} from 'use-immer';
import {Table,IconButton,TableBody,Snackbar,TableContainer,TableCell,TableHead,TableRow,TableSortLabel} from '@mui/material'
import * as nodeService from '../../Shared/Services/nodeConfiguration/node.configuration.service.js'

export default function  NodeConfig(props){
    const [addState,setAddState]=useState(true);
    const [nodesDropdown,setNodesDropDown]=useState([{value: "",name:"--Select--"}]);
    const [nodeDetails,setNodeDetails]=useImmer({
        nodeconfigid:0,
        nodename: "--Select--",
        aliasname: "",
        markettype:"",
        headernode:false,
        headermarkettype: "",
    });
    const [orderBy,setOrderBy]=useState("")
    const [sortType,setSortType]=useState("asc")
    const [warningDialog,setWarningDailog]=useState(false)
    const [isDeleteWarning,setDeleteWarning]=useState(false)
    const [configuredNodes,setConfiguredNodes]=useState([])
    let [deleteNodeConfig,setDeleteNode]=useState(undefined)
    const [isSnackOpen,setSnack]=useState(false)
    const [snackMessage,setSnackMessage]=useState(false)
    let deleteAlert="Are you sure you want to delete the configuration."
    let cancelAlert="Are you sure you want to cancel the update process."
    const tableHeaders=[{
        id:"nodename",
        label:"Node Name",
        width: "18.2%"
    },{
        id:"aliasname",
        label:"Node Alias Name",
        width: "16.5%"
    },{
        id:"markettype",
        label:"Market Details",
        width: "16.2%"
    },{
        id:"headernode",
        label:"Header Node",
        width: "10.8%"
    },{
        id:"headermarkettype",
        label:"Header Market Details",
        width: "16.3%"
    }]

    useEffect(()=>{
        
        fetchNodeConfig();
        
    },[])

    function fetchNodeConfig(){
        nodeService.getAllNodeConfigurationDetails().then((result)=>{
            if(orderBy===""){
                setConfiguredNodes(result.data)
            }
            else{
                let obj=nodeService.sortObjectArray(result.data,orderBy,sortType)
                setConfiguredNodes(obj)
            }
            
        })
        nodeService.getNodesDropdown().then((result)=>{
            setNodesDropDown([{"id": 0,"descprition": "--Select--"},...result.data])
        })
    }



    const [marketTypeOpen,setOpen]=useState(false);
    const [marketHeader,setOpen1] = useState(false);
    
    const closeMarketType=()=>{
        setOpen(false)
    }

    const closeMarketHeader=()=>{
        setOpen1(false)
    }

    function loadExistingNode(node){
        setAddState(false)
        nodeService.getIndividualNodeData({"nodeconfigid":node.nodeconfigid}).then((result)=>{
            setNodeDetails((prevNodeDetails)=>{
                prevNodeDetails.nodeconfigid= result.data[0].nodeconfigid
                prevNodeDetails.nodename= result.data[0].nodename
                prevNodeDetails.aliasname= result.data[0].aliasname
                prevNodeDetails.markettype= result.data[0].markettype?result.data[0].markettype:""
                prevNodeDetails.headernode=result.data[0].headernode
                prevNodeDetails.headermarkettype= result.data[0].headermarkettype?result.data[0].headermarkettype:""
            });
        })
        
    }

    function validation(){
        if(nodeDetails.nodename==="--Select--"){
            alert("Please enter Node name")
            return false;
        }
        else if(nodeDetails.aliasname===""){
            alert("Please enter Node Alias name")
            return false;
        }
        else if(nodeDetails.markettype===""){
            alert("Please select atleast one Market type")
            return false;
        }
        else  if(nodeDetails.headernode===true && nodeDetails.headermarkettype===""){
            alert("Please select atleast one Header Market type")
            return false;
            
        }
        return true;
        
    }

    function addNode(){
        if(!validation()){
            return
        }
        let obj={...nodeDetails}
        obj['nodeconfigid']=0;
        obj["createdby"]="string"
        obj["createdon"]= "2023-12-01T08:15:14.689Z"
        nodeService.createNode(obj).then((result)=>{
            obj["nodeconfigarationavailability"]=false
            obj["lastupdatedby"]= "string"
            obj["lastupdatedon"]= "2023-12-01T08:38:16.210Z"
            setSnackMessage(result.data)
            setSnack(true)
            nodeService.updateUsedNodes(obj).then((result)=>{
                fetchNodeConfig()
                clearForm()
            })
        })
    }

    function updateNode(){
        if(!validation()){
            return
        }
        let obj={...nodeDetails,
            "lastupdatedby": "string",
            "lastupdatedon": "2023-12-02T03:59:09.234Z"
            }
        nodeService.updateNode(obj).then((result)=>{
            setSnackMessage(result.data)
            setSnack(true)
            fetchNodeConfig()
            clearForm()
        })
        
    }
    function clearForm(){
        setAddState(true)
        setNodeDetails((node)=>{
            node.nodeconfigid=0
            node.nodename="--Select--";
            node.aliasname="";
            node.markettype="";
            node.headernode=false;
            node.headermarkettype="";
        })
        setWarningDailog(false)
    }
    function deleteNode(){
        if(deleteNodeConfig.nodeconfigid==nodeDetails.nodeconfigid){
            clearForm()
        }
        nodeService.deleteNode({"nodeconfigid": deleteNodeConfig.nodeconfigid}).then((result)=>{
            let obj={...deleteNodeConfig}
            obj["nodeconfigarationavailability"]=true
            nodeService.updateUsedNodes(obj).then((result)=>{
                fetchNodeConfig()
            })
            setSnackMessage(result.data)
            setSnack(true)
            setDeleteNode(undefined)
        })
        setWarningDailog(false)       
    }
    
    

    function setMarketTypes(val){
        setNodeDetails(node=>{node.markettype=val})
    }
    function setHeaderMarketTypes(val){
        setNodeDetails(node=>{node.headermarkettype=val})
    }

    function sortFunction(id){
        let localSortType="asc";
        if(orderBy===id){
           localSortType=sortType==="asc"?"desc":"asc";
            setSortType(sortType==="asc"?"desc":"asc");
        }else{
            setSortType("asc");
            setOrderBy(id);
        }
        let obj=nodeService.sortObjectArray(configuredNodes,id,localSortType)
        setConfiguredNodes(obj)
    }
    
    let action=(<IconButton color="inherit" onClick={() => { setSnack(false) }}><CloseIcon /></IconButton>)
    return (
        <>
        <div data-testid="nodeConfiguration" className="formStyling">
            {addState?<select data-testid="nodeName" name="nodes" id="nodes" value={nodeDetails.nodename} onChange={(event)=>setNodeDetails(node=>{node.nodename= event.target.value})} >
                        {nodesDropdown.map((node)=><option key={node.descprition} value={node.descprition}>{node.descprition}</option>)}
                    </select>:
            <input type="text" data-testid="nodeName1" style={{"width":"97%"}} value={nodeDetails.nodename} name="nodes" id="nodes" disabled/>
            }
            <input type="text" className="input" data-testid="aliasName" id="aliasName" value={nodeDetails.aliasname} onChange={(event)=>setNodeDetails(node=>{node.aliasname= event.target.value})}/>
            <div className="marketSelection">
                <input  data-testid="marketSelectionBox"className="marketType input" type="text" id="" value={nodeDetails.markettype} disabled/>
                <button data-testid="selctionButton"  className="selectionIcon" onClick={(event)=>{setOpen(true)}}><ChecklistIcon fontSize='large'/></button>
                <AddRemove className="dailog"
                open={marketTypeOpen}
                closeHandler={closeMarketType}
                selectedMarkets={nodeDetails.markettype}
                updateMarketType={setMarketTypes}/>
            </div>
            <input type="checkbox" className="input" data-testid="checkBox" id="headerNode" checked={nodeDetails.headernode} onChange={(event)=>setNodeDetails(node=>{node.headernode= event.target.checked;node.headermarkettype=event.target.checked?node.headermarkettype:""})}/>
            <div className="marketSelection">
                <input  className="marketType input" type="text" id="" value={nodeDetails.headernode?nodeDetails.headermarkettype:""} disabled/>
                <button disabled={!nodeDetails.headernode} data-testid="headerSelctionButton" className="selectionIcon" onClick={(event)=>{setOpen1(true)}}><ChecklistIcon fontSize='large'/></button>
                <HeaderMarket className="dailog"
                open={marketHeader}
                closeHandler={closeMarketHeader}
                selectedMarkets={nodeDetails.headermarkettype}
                updateMarketType={setHeaderMarketTypes}
                />
            </div>
            {addState?<Button
            variant="contained"
            color="success"
            data-testid="addFormButton"  onClick={addNode} >Add</Button>:
            <div>
                <Button  variant="contained" color="success" disabled={(nodeDetails.aliasname=== "")} data-testid="updateFormButton" onClick={updateNode} className="updateButtton">Update</Button>
                <Button color="secondary" variant="contained"  data-testid="cancelFormButton" onClick={()=>{setWarningDailog(true);setDeleteWarning(false)}} className="updateButtton">Cancel</Button>
            </div>}
        </div>
        <TableContainer className="tablecontainer">
        <Table  stickyHeader>
           
            <TableHead sx={{height: "20px",padding: "0px",margin:"0px"}}>
                <TableRow>
                    {tableHeaders.map((header)=>{
                        return <TableCell key={header.id} size="small" sx={{textAlign:"center",width: header.width,padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>
                                    <TableSortLabel
                                    data-testid="header.id"
                                    active={orderBy===header.id}
                                    direction={sortType}
                                    onClick={()=>{sortFunction(header.id)}}>
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                    })}
                    <TableCell  size="small" sx={{textAlign:"center",width: "10%",padding: "7px",fontSize:"12px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>
                        Update            
                    </TableCell>
                    <TableCell  size="small" sx={{textAlign:"center",width: "10%",padding: "7px",fontSize:"12px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>
                        Delete            
                    </TableCell>
                    
                </TableRow>
            </TableHead>
            <TableBody>
                {configuredNodes.map((node,i)=>{
                    return  <TableRow size="smalll" data-testid="nodes" key={node.nodeconfigid}>
                   
                            <TableCell >{node.nodename}</TableCell>
                            <TableCell >{node.aliasname}</TableCell>
                            <TableCell >{node.markettype}</TableCell>
                            <TableCell >{node.headernode?"Yes":"No"}</TableCell>
                            <TableCell >{node.headermarkettype}</TableCell>
                            <TableCell ><Button size="smalll" variant="contained" color="success" data-testid="update" onClick={()=>{loadExistingNode(node)}}>Update</Button></TableCell>
                            <TableCell ><Button size="smalll" variant="contained" color="secondary"  data-testid="delete" onClick={()=>{setDeleteNode(node);setWarningDailog(true);setDeleteWarning(true);}}>Delete</Button></TableCell>
                        </TableRow>
                })}
            </TableBody>
        </Table>
        </TableContainer>
        <Snackbar anchorOrigin={{vertical: "bottom",horizontal:"center"}} action={action} open={isSnackOpen} message={snackMessage} onClose={()=>{setSnack(false)}}/>
            
        
        {warningDialog?
        <Dialog
        sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "50%",
                    maxWidth: "350px",  // Set your width here
                    height: "50%",
                    maxHeight: "150px"
                },
            },
        }}
        open={warningDialog}>
            <DialogTitle>
                <div className="header">
                    <div className="divInline">Confirmation</div>
                    <button className="closeButton" onClick={() => { setWarningDailog(false) }}><CloseIcon /></button>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>{isDeleteWarning?deleteAlert:cancelAlert}</div>
            </DialogContent>
            <DialogActions>
                <button data-testid="okButton" onClick={()=>{isDeleteWarning?deleteNode():clearForm()}} style={{float:"right",height:"20px"}} >OK</button>
                <button data-testid="reject" onClick={()=>{setWarningDailog(false)}} style={{ float:"right",height:"20px"}} >Cancel</button>
            </DialogActions>
        </Dialog>:null}
        </>
    );
}


