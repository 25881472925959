import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import './header.market.details.scss'
import {useEffect } from 'react';
import {useImmer} from 'use-immer';
import * as nodeService from '../../Shared/Services/nodeConfiguration/node.configuration.service.js'

export default function HeaderMarket(props) {
    const { closeHandler, open , selectedMarkets,updateMarketType} = props;
    let markets;
    const [marketTypes,setMarketTypes]=useImmer({data:[]});
    useEffect(()=>{
        nodeService.getHeaderMarketType().then((result)=>{
            markets=result.data
            let obj=markets.map((market)=>{return {...market, isSelected:selectedMarkets.includes(market.descprition)}})
            setMarketTypes(data=>{data.data=obj})
        })
    },[selectedMarkets,open])

    function changeSelectioninnerWidth(event,i){
        setMarketTypes(data=>{data.data[i].isSelected=event.target.checked})
    }
    function onSave(){
        if(marketTypes.data.filter(marketType=>{return marketType.isSelected}).length===0){
            alert("Please select atleast one Market type")
            return;
        }
        updateMarketType(marketTypes.data.filter(marketType=>{return marketType.isSelected}).map((marketType,i)=>{return marketType.descprition}).join(","))
        closeHandler()
    }
    return (
        <>
            <Dialog data-testid="headerMarketTest" sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "50%",
                        maxWidth: "55%",  // Set your width here
                        height: "50%",
                        maxHeight: "55%"
                    },
                },
            }}
                 open={open}>
                <DialogTitle>
                    <div className="header">
                        <div className="divInline">Market Type Configuration</div>
                        <button className="closeButton" onClick={() => { closeHandler() }}><CloseIcon /></button>
                    </div>
                </DialogTitle>
                <div style={{ display: "flex" }}>
                    <h3 style={{ marginLeft: 30 + 'px' }}>Available Market Types</h3>
                    <h3 style={{ marginLeft: 194 + 'px' }}>Market Types to be Added</h3>
                </div>
                <div style={{ display: "flex", overflow: "auto" }}>
                    <div className="Box1" style={{ overflow: "auto" }}>
                        <table style={{ width:'100%'}}>
                           {marketTypes.data.map((marketType,i)=>{
                               return (<tr key={marketType.id}>
                                        <td id={marketType.descprition} style={{width: 50 + '%'}}>{marketType.descprition}</td>
                                        <td><input type="checkbox" id={marketType.descprition} onChange={(event)=>{changeSelectioninnerWidth(event,i)}} checked={marketType.isSelected} /></td>
                                      </tr>)
                           })}
                        </table>
                    </div>
                    <div className="Box2" style={{ overflow: "auto" }}>
                        <table style={{ width:'100%'}}>
                        {marketTypes.data.filter(marketType=>{return marketType.isSelected}).map((marketType,i)=>{
                            return (<tr key={marketType.id}>
                                        <td id={marketType.descprition} style={{fontSize:15+ 'px'}}>{marketType.descprition}</td>
                                    </tr>)
                        })}
                        </table>
                    </div>
                </div>
                <div>
                    <button style={{ width: 70 + 'px', height: 30 + 'px', marginLeft: 240 + 'px', marginTop: 50 + 'px' }} onClick={()=>{onSave()}}>Save</button>
                    <button style={{ width: 70 + 'px', height: 30 + 'px', marginLeft: 20 + 'px' }} onClick={() => { closeHandler() }} className="cancel">Cancel</button>
                </div>
            </Dialog>
        </>
    )

}