import axios from 'axios';

const apiURL=process.env.REACT_APP_BASE_URL

export function getGraphNodeTable(){
    return axios.get(apiURL + "GraphConfiguration/GetGraphNodeTable")
}

export function getGraphLegendConfigurationTable(){
    return axios.get(apiURL + "GraphConfiguration/GetGraphLegendConfigurationTable")
}

export function updateGraphLegend(data){
    return axios.post(apiURL + "GraphConfiguration/UpdateGraphLegendConfiguration",data)
}

export function updateChildGraph(data){
    return axios.post(apiURL + "GraphConfiguration/UpdateChildGraphConfiguration",data)
}

export function sortObjectArray(data,orderBy,sortType){
    console.log(data,orderBy,sortType)
    return data.sort((a,b)=>{return ((a[orderBy]?a[orderBy]:"").toString().toUpperCase()>(b[orderBy]?b[orderBy]:"").toString().toUpperCase()?1:-1)*(sortType==="asc"?1:-1)})
}