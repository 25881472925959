import React, { useEffect, useState } from 'react';
import './alarm_color.scss';
import {Button,Table,TableBody,TableSortLabel,TableHead,TableRow,TableCell} from '@mui/material'
import * as alarmColorService from '../../Shared/Services/alarm_color/alarm_color.service.js';
import { useImmer } from 'use-immer';
import {colorPalette,getColorName} from '../colorPaletteConfiguration/colorPaletteConfiguration.js'


export default function AlarmColor(){
    let user={userId:"admin"}
    let tableLabel={
        "alarmPos2Color": "Alarm+2",
        "alarmPos1Color": "Alarm+1",
        "alarmNeg1Color": "Alarm-1",
        "alarmNeg2Color": "Alarm-2",
    }
    let postData={
        "userId": "admin",
        "alarmPos2Color": "#Ff0000",
        "alarmPos1Color": "#00ff00",
        "alarmNeg1Color": "#00ff00",
        "alarmNeg2Color": "#ffff00",
        "createdBy": "admin",
        "createdOn": "2023-12-20T06:15:38.231Z",
        "lastUpdatedBy": "admin",
        "lastUpdatedOn": "2023-12-20T06:15:38.231Z"
      }
    const [tableData,setTableData]=useImmer({})
    const [sortType,setSortType]=useState("asc")
    const [sortActive,setSortActive]=useState(false)
    const [sortLabels,setSortLabels]=useState([
        "alarmPos2Color",
        "alarmPos1Color",
        "alarmNeg1Color",
        "alarmNeg2Color",
    ])

    const [alarmColorForm,setAlarmColor]=useImmer({
        alarmKey:"",
        alarmColor:"#000000"
    })
    const [isUpdate,setIsUpdate]=useState(false)

   

    function loadForUpdate(key,color){
        setIsUpdate(true)
        setAlarmColor(draft=>{
            draft.alarmKey=key;
            draft.alarmColor=color;
        })
    }
    
    function createUpdate(){
        let data={...tableData}
        data[alarmColorForm["alarmKey"]]=alarmColorForm.alarmColor
        alarmColorService.createUpdateAlarmColors(data).then((res)=>{
            alarmColorService.getAlarmColors().then((result)=>{
                setTableData(result.data[0])
                alert("Updated Successfully")
                clearForm()
            })
        })
    }

    function clearForm(){
        setAlarmColor({
            alarmKey:"",
            alarmColor:"#000000"
        })
        setIsUpdate(false)
    }
    function cancelUpdate(){
        if(!window.confirm("Are you sure you want to cancel the update process?")){
            return;
        }
        clearForm()
    }
    function sortFunction(){
        setSortActive(true)
        setSortType(sortType==="asc"?"desc":"asc");
        let localSortType=sortType==="asc"?"desc":"asc"
        let obj=sortLabels.sort((a,b)=>{
            return tableLabel[a]>tableLabel[b]?1:-1})
        if(localSortType==="desc"){
            obj.reverse()
        }
        setSortLabels(obj)
    }

    useEffect(()=>{
        alarmColorService.getAlarmColors(user).then((result)=>{
            setTableData(result.data[0])
        })
    },[])


    return (<>
        <div className="AlarmColorFormStyle">
            <input data-testid="colorLevel" type="text" value={tableLabel[alarmColorForm.alarmKey]?tableLabel[alarmColorForm.alarmKey]:""} name="" id="" disabled/>
            <div className="inputColor">
                <input disabled={!isUpdate} type="color" value={alarmColorForm.alarmColor} onChange={e=>{setAlarmColor(draft=>{draft.alarmColor=e.target.value})}} name="" id="" list="colors"/>
                <datalist id="colors">
                    {colorPalette.map((color) => {
                            return <option value={"#" + color.HEX}>
                                
                            </option>
                    })}
                </datalist>
                <div>{(getColorName(alarmColorForm.alarmColor))?getColorName(alarmColorForm.alarmColor)["Colorname"]:alarmColorForm.alarmColor.toUpperCase()}</div>
            </div>
           
            <Button data-testid="updateFormButton" disabled={!isUpdate} onClick={createUpdate} variant="contained" color="success" className="updateFormButon">Update</Button>
            <Button data-testid="cancelFormButton" disabled={!isUpdate} onClick={cancelUpdate} variant="contained" color="secondary" className="cancelFormButton">Cancel</Button>
        </div>
        <Table sx={{ width:"36%"}}>
            <TableHead>
                <TableRow >
                    <TableCell sx={{textAlign:"center",fontWeight: "bold",backgroundColor:"#6DC9C9", width:"28%"}}>
                        <TableSortLabel active={sortActive} direction={sortType} onClick={()=>{sortFunction();}}>
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ textAlign:"center",fontWeight: "bold",backgroundColor:"#6DC9C9",width:"40%"}}>Color</TableCell>
                    <TableCell sx={{textAlign:"center",fontWeight: "bold",backgroundColor:"#6DC9C9", width:"28%"}}>Update</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sortLabels.map(key=>{
                    return <TableRow key={tableLabel[key]}>
                                <TableCell>{tableLabel[key]}</TableCell>
                               { tableData[key]?<TableCell>
                                    <div className="colorData">
                                        <input type="color" disabled value={tableData[key]} name="" id=""/>
                                        <div>{(getColorName(tableData[key]))?getColorName(tableData[key])["Colorname"]:tableData[key].toUpperCase()}</div>
                                    </div>
                                </TableCell>:<TableCell></TableCell>}
                                <TableCell><Button data-testid="update" onClick={()=>{loadForUpdate(key,tableData[key])}} sx={{width:"100%"}} variant="contained" color="success" >Update</Button></TableCell>
                            </TableRow>
                })}
            </TableBody>
        </Table>
    </>)
}