import React, { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import './tableview.scss';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {getAllNodeConfigurationDetails} from '../../Shared/Services/nodeConfiguration/node.configuration.service.js'
import {getTableView, checkLongDayDataAvailability} from '../../Shared/Services/tableView/tableView.service.js'
import { formatNegativeValues } from '../../utilities/formatMethods.js'

function childRow(marketType,priceType,alarmData,alarmColors,aliasName, tableHeader){
    // let tableHeader=["HE1", "HE2", "HE3", "HE4", "HE5", "HE6", "HE7", "HE8", "HE9", "HE10", "HE11", "HE12", "HE13", "HE14", "HE15", "HE16", "HE17", "HE18", "HE19", "HE20", "HE21", "HE22", "HE23", "HE24"];
    let fixedIntervals={
        "IFM":["0","100","1000"],
        "RTM":["0","1","2","3","4","5","6","7","8","9","10","11","12","100"],
        "FMM":["0","1","2","3","4","100","1000"],
        "HASP":["0","1","2","3","4","100","1000"],
    }
    function getChildLabel(intervalNo,ptype){
        if(intervalNo==100){
            if(ptype=="IFM")
                return "Yesterday's IFM($)"
            return "Yesterday's AVG($)"
        }
        if(intervalNo==1000){
            if(ptype=="IFM")
                return "Tomorrow's IFM($)"
            return "Tomorrow's AVG($)"
        }
        return "Interval "+intervalNo+" ($)"
    }
    let backColors=alarmData.filter(almdata=>{
        return aliasName==almdata.aliasName && priceType==almdata.marketPriceType;
    })
    return fixedIntervals[priceType].map((intervalNo)=>{
        if(intervalNo==0){
            return null;
        }
        let intervalData=marketType.children.find((mi)=>mi.intervalNo==intervalNo)
        if(intervalData===undefined){
            return (<tr key={intervalNo}>
                        <td style={{backgroundColor: "white"}}></td>
                        <td style={{backgroundColor: "white"}}>{getChildLabel(intervalNo,priceType)}</td>
                        {tableHeader.map((unused,k)=>{
                            return <td></td>
                        })}
                    </tr>)
        }
        else{
            return (<tr key={intervalNo}>
                <td style={{backgroundColor: "white"}}></td>
                <td style={{backgroundColor: "white"}}>{getChildLabel(intervalData.intervalNo,priceType)}</td>
                {tableHeader.map((unused,k)=>{
                    
                    let hourData=intervalData.children.find((data)=>{return unused.substring(2)==data.hoursCount});
                    
                    if(hourData)
                        {
                            let finalBackColor=backColors.find((bcolors)=>{
                                return bcolors.todayHoursCount==hourData.hoursCount && bcolors.intervalNumber==intervalNo
                            })
                            return <td style={finalBackColor && hourData.amountValue==finalBackColor.priceValue?{backgroundColor:alarmColors[finalBackColor.colorKey]}:{}} key={hourData.hoursCount}>{formatNegativeValues(hourData.amountValue)}</td>
                        }
                    else {return <td ></td>}
                })}
            </tr>)
        }
        
    })
}

const marketColors = {
    'IFM': 'red',
    'HASP': 'green',
    'FMM': 'black',
    'RTM': 'blue'
}

function parentRow(marketType,tableHeader){
    let intervalData=marketType.children.find((data)=>{return data.intervalNo=='0'});
    return  tableHeader.map((unused,j)=>{
        let hourData
        if(intervalData)
            hourData=intervalData.children.find((data)=>{return unused.substring(2)==data.hoursCount});
        if(intervalData && hourData)
            {return <td className="hourdata" style={{color: marketColors[marketType.priceType]}} key={hourData.hoursCount}>{formatNegativeValues(hourData.amountValue)}</td>}
        else 
            {return <td className="hourdata"></td>}
    })
}
 
export default function Tableview(props) {
    const { settingsCloseFlag,lastUpdatedTime,alarmData,alarmColors } = props;
    const marketOrder = ['IFM','HASP','FMM','RTM']
    const [tableHeader, setTableHeader]= useImmer(["HE1", "HE2", "HE3", "HE4", "HE5", "HE6", "HE7", "HE8", "HE9", "HE10", "HE11", "HE12", "HE13", "HE14", "HE15", "HE16", "HE17", "HE18", "HE19", "HE20", "HE21", "HE22", "HE23", "HE24"]);
    const [tableData, setTableData] = useImmer([]);
    let rowStyle1={"backgroundColor":"rgb(230,242,247)"}
    let rowStyle2={"backgroundColor":"rgb(187,220,234)"}
    
    function getStyling(i){
        
        if(i%2==0){
            return rowStyle1;
        }
        return rowStyle2;
    }
    
    
    
    useEffect( () => {
        let fetchapi=true
        function tableView(){
            checkLongDayDataAvailability({tableView: true, nodeName: ""}).then((response)=>{
                if(response.data.length!==0 && response.data[0].status === "True"){
                    if(tableHeader.length !== 25) setTableHeader(d=>{d = d.push("HE25")});
                } 
            })
            let prevTableData=tableData
            getAllNodeConfigurationDetails().then(async (result)=>{
                setTableData((draft)=>{return []})
                let data=result.data

                for(let i=0;i<data.length;i++){
                    //market type with ordering
                    let marketType = data[i].markettype.split(',')
                    marketType = marketOrder.filter(type => marketType.includes(type))
                    //getting node values
                    let result1 = (await getTableView({ node_name: data[i].nodename })).data
                    let data1 = { nodeName: result1.nodeName, aliasname: data[i].aliasname, children: [] }
                    let prevData=prevTableData.find((k)=>{
                        return k.aliasname==data1.aliasname
                    })
                    //creating table data
                    marketType.forEach(element => {
                        let child = result1.children.find(val => val.priceType===element)
                        if(child) {
                            let prevMarketData=null
                            if(prevData)
                                prevMarketData=prevData.children.find(val => val.priceType===element)
                            data1.children.push({...child, isExp: prevMarketData?prevMarketData.isExp:false})
                        }
                    })
                    if(fetchapi) setTableData( draft=>draft.concat(data1) )
                }
            })
        }
        tableView()
        // let timeId = setInterval(tableView,120000)
        // setTableData(dummyData)
        return ()=>{
            fetchapi=false
            // clearInterval(timeId)
        }
    }, [lastUpdatedTime]);
    

    return (
        <>
            <div className='tableview'>
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>Node Alias Name</th>
                            <th >Price Type</th>
                            {tableHeader.map((e) => (<th>{e}</th>))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((nodeDetails,i)=>{
                            return nodeDetails.children.map((marketType,l)=>{
                                return <React.Fragment key={nodeDetails.nodeName+" "+marketType.priceType}><tr style={getStyling(i)} >
                                    <td>{nodeDetails.aliasname}</td>
                                    <td  style={{color: marketColors[marketType.priceType]}}>{marketType.priceType}
                                    <span style={{ float: "right", cursor: "pointer", color: 'black' }} onClick={() => { setTableData((d) => { d[i].children[l].isExp = !d[i].children[l].isExp }) }}>{marketType.isExp ? <b><RemoveIcon /></b> : <b><AddIcon /></b>}</span></td>
                                    {parentRow(marketType,tableHeader)}
                                </tr>
                                {
                                    marketType.isExp?
                                        childRow(marketType,marketType.priceType,alarmData,alarmColors,nodeDetails.aliasname,tableHeader)
                                    :null
                                }
                                </React.Fragment>
                            })
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );

}
