import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, TableSortLabel } from '@mui/material';
import Button from "@mui/material/Button";
import React, { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import * as graphService from '../../Shared/Services/graphColorService/graphColor.service.js';
import * as nodeService from '../../Shared/Services/nodeConfiguration/node.configuration.service.js';
import '../graph_configuration/graph_configurtion.scss';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import {colorPalette,getColorName} from '../colorPaletteConfiguration/colorPaletteConfiguration.js'


export default function GraphConfig(props) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledFirst, setIsDisabledFirst] = useState(true);
  const [configuredGraph, setConfiguredGraph] = useState([])
  const [configuredGraph1, setConfiguredGraph1] = useState([])
  const [nodeNameList, setnodeNameList] = useState([])
  const [addState, setAddState] = useState(true);
  const [snackMessage, setSnackMessage] = useState(false)
  const [isSnackOpen, setSnack] = useState(false)
  const [warningDialog, setWarningDailog] = useState(false)
  const [isDeleteWarning, setDeleteWarning] = useState(false)
  const [warningDialog1, setWarningDailog1] = useState(false)
  const [isDeleteWarning1, setDeleteWarning1] = useState(false)
  const [orderBy, setOrderBy] = useState("")
  const [sortType, setSortType] = useState("asc")


  let cancelAlert = "Are you sure you want to cancel the update process."
  let deleteAlert = "Are you sure you want to delete the configuration."
  let cancelAlert1 = "Are you sure you want to cancel the update process."
  let deleteAlert1 = "Are you sure you want to delete the configuration."

  const [nodeDetails, setNodeDetails] = useImmer({
    id: 0,
    nodeName: "",
    aliasName: "",
  })
  const [graphDetails, setGraphDetails] = useImmer({
    id: 0,
    name: "",
    colorCode: "#000000",
    showInGraph: false,
    marketTypeName: ""
  })

  const tableHeaders = [{
    id: "marketTypeName",
    label: "Name"
  },
  {
    id: "colorCode",
    label: "Color"
  },
  {
    id: "showInGraph",
    label: "Show in Graph"
  }]

  const tableHeaders1 = [{
    id: "nodeName",
    label: "Node Name"
  },
  {
    id: "aliasName",
    label: "Node Alias Name"
  }]

  function loadExistingNode(node) {
    setIsDisabled(false)
    setNodeDetails((prevNodeDetails) => {
      prevNodeDetails.id = configuredGraph[0].id
      prevNodeDetails.nodename = configuredGraph[0].nodeName
      prevNodeDetails.aliasname = configuredGraph[0].aliasName
    });
  }

  function updateGraphColor(node) {
    setIsDisabledFirst(false)
    setGraphDetails((previousGraphDetails) => {
      previousGraphDetails.id = node.id
      previousGraphDetails.colorCode = node.colorCode
      previousGraphDetails.showInGraph = node.showInGraph
      previousGraphDetails.marketTypeName = node.marketTypeName
    });
  }

  function fetchGraphConfig() {
    graphService.getGraphNodeTable().then((result) => {
      setConfiguredGraph(result.data)
    })
  }

  function fetchGraphConfig1() {
    graphService.getGraphLegendConfigurationTable().then((result) => {
      setConfiguredGraph1(result.data)
    })
  }

  function getNodeNameDropdown() {
    nodeService.getAllNodeConfigurationDetails().then((result) => {
      setnodeNameList([{ "nodeconfigid": 0, "nodename": "--Select--" }, ...result.data])
    })
  }

  function clearForm() {
    setIsDisabled(true)
    setNodeDetails((node) => {
      node.id = 0
      node.nodename = "--Select--";
      node.aliasname = "";
    })
    setWarningDailog(false)
  }

  function clearGraph() {
    setIsDisabledFirst(true)
    setGraphDetails((node) => {
      node.id = 0
      node.colorCode = "#000000";
      node.showInGraph = false;
      node.marketTypeName = "";
    })
    setWarningDailog1(false)
  }

  function updateNode() {
    if(!validation()){
      return
    }
    let obj = {
      ...nodeDetails,
      "lastupdatedby": "TestUser",
      "lastupdatedon": "2023-12-02T03:59:09.234Z"
    }
    graphService.updateChildGraph(obj).then((result) => {
      setSnackMessage(result.data)
      setSnack(true)
      fetchGraphConfig()
      clearForm()
    })
  }

  function updateGraph() {
    let obj = {
      ...graphDetails,
      "lastUpdatedBy": "TestUser",
      "lastUpdatedOn": "2023-12-20T08:20:08.790Z"
    }
    graphService.updateGraphLegend(obj).then((result) => {
      setSnackMessage(result.data)
      setSnack(true)
      fetchGraphConfig1()
      clearGraph()
    })
  }

  useEffect(() => {
    fetchGraphConfig();
    fetchGraphConfig1();
    getNodeNameDropdown()
  }, [])

  

  function sortFunction(id) {
    let localSortType = "asc";
    if (orderBy === id) {
      localSortType = sortType === "asc" ? "desc" : "asc";
      setSortType(sortType === "asc" ? "desc" : "asc");
    } else {
      setSortType("asc");
      setOrderBy(id);
    }
    let obj = graphService.sortObjectArray(configuredGraph1, id, localSortType)
    setConfiguredGraph1(obj)
  }

  function sortFunction1(id) {
    let localSortType = "asc";
    if (orderBy === id) {
      localSortType = sortType === "asc" ? "desc" : "asc";
      setSortType(sortType === "asc" ? "desc" : "asc");
    } else {
      setSortType("asc");
      setOrderBy(id);
    }
    let obj = graphService.sortObjectArray(configuredGraph, id, localSortType)
    setConfiguredGraph(obj)
  }

  function validation(){
   if(nodeDetails.nodename==="--Select--"){
      alert("Please Select Node Name")
    }
  else if(nodeDetails.aliasname==""){
    alert("Node Alias name cannot be empty")
    return false
  }
    else{
      return true;
    }
  }


    
  let action = (<IconButton color="inherit" onClick={() => { setSnack(false) }}><CloseIcon /></IconButton>)
  return (
    <div style={{ "max-height":"55vh", "overflow-y":"scroll"}}>
      <div className="formStyling">

        <select style={{ width: 225 + 'px', height: 30 + 'px' }} disabled={isDisabled} name="nodes" id="nodes" value={nodeDetails.nodename} onChange={(event) => setNodeDetails(node => { node.nodename = event.target.value })}>
          {nodeNameList.map((node) => <option key={node.nodeconfigid} value={node.nodename}>{node.nodename}</option>)}
        </select>
        <input type="text" className="input" name="aliasname" id="aliasname" style={{ width: 220 + 'px', marginLeft: 50 + 'px' }} value={nodeDetails.aliasname} disabled={isDisabled} onChange={(event) => setNodeDetails(node => { node.aliasname = event.target.value })} />
        <div style={{ paddingleft: 156 + 'px', marginLeft: 120 + 'px' }} className="ButtonUpdateandCancel">
          <Button style={{ width: 70 + 'px', height: 30 + 'px', }} disabled={isDisabled} onClick={updateNode} variant="contained" color="success">Update</Button>
          &nbsp;
                <Button color="secondary" variant="contained" style={{ width: 70 + 'px', height: 30 + 'px' }} disabled={isDisabled} onClick={() => { setIsDisabled(true); setWarningDailog(true); }}>Cancel</Button>
        </div>
      </div>
      <div style={{ paddingTop: 20 + 'px' }}>
        <TableContainer>
          <Table stickyHeader sx={{ width: 60 + '%' }}>
            <TableHead sx={{ width: 60 + '%' }} aria-label="simple table">
              <TableRow>
                {tableHeaders1.map((headers) => {
                  return <TableCell key={headers.id} size="small" sx={{ textAlign: "center", width: 300 + 'px', padding: "7px", margin: "7px", fontWeight: "bold", backgroundColor: "#6DC9C9" }}>
                    <TableSortLabel
                      active={orderBy === "headers.id"}
                      direction={sortType}
                      onClick={() => { sortFunction1(headers.id) }}>
                      {headers.label}
                    </TableSortLabel>
                  </TableCell>
                })}
                <TableCell style={{ width:170 + 'px', backgroundColor: "#6DC9C9", fontSize: 15 + 'px', textAlign: "center", fontWeight: "bold" }}>Update</TableCell>

                {/* <TableCell style={{ width: 25 + '%', backgroundColor: "#6DC9C9", height: 25 + 'px', fontSize: 15 + 'px' }}>Node Name</TableCell>
                <TableCell style={{ width: 25 + '%', backgroundColor: "#6DC9C9", fontSize: 15 + 'px' }}>Node Alias Name</TableCell>
                <TableCell style={{ width: 0.1 + '%', backgroundColor: "#6DC9C9", fontSize: 15 + 'px' }}>Update</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {configuredGraph.map((node, i) =>
                <TableRow>
                  <TableCell>{node.nodeName}</TableCell>
                  <TableCell>{node.aliasName}</TableCell>
                  <TableCell><Button style={{ width: 170 + 'px', height: 30 + 'px' }} onClick={() => { setIsDisabled(false); { loadExistingNode(node) } }} variant="contained" color="success">Update</Button></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} action={action} open={isSnackOpen} message={snackMessage} onClose={() => { setSnack(false) }} />
      </div>
      <p className="heading" style={{ fontSize: 20 + 'px' }}>Graph Legend</p>

      <div style={{ marginLeft: 3 + 'px' }} className='upper_section'>

        <input type="text" className="input" style={{ width: 200 + 'px', height: 25 + 'px' }} value={graphDetails.marketTypeName} disabled></input>
        <div style={{ width: '20%', height: '30px', marginLeft: 25 + 'px' }}>
          <input type="color" disabled={isDisabledFirst} list="colors" style={{ width: 50 + '%', height: 30 + 'px' }} value={graphDetails.colorCode} onChange={(event) => setGraphDetails(node => { node.colorCode = event.target.value })}>
          </input>
          <datalist id="colors">
            {colorPalette.map((color) => {
              return <option value={"#" + color.HEX}>

              </option>
            })}
          </datalist>
          <div disabled={isDisabledFirst} style={{ float: 'right', width: 50 + '%', height: 30 + 'px', textAlign: 'center', boxSizing: 'border-box', border: '1px solid buttonborder', lineHeight: '30px' }}>{getColorName(graphDetails.colorCode.toUpperCase())?getColorName(graphDetails.colorCode.toUpperCase()).Colorname:graphDetails.colorCode.toUpperCase()}</div>
        </div>
        <input type="checkbox" style={{ marginLeft:70 + 'px' }} disabled={isDisabledFirst} checked={graphDetails.showInGraph} onChange={(event) => setGraphDetails(node => { node.showInGraph = event.target.checked })}></input>
        <Button style={{ width: 70 + 'px', height: 30 + 'px', marginLeft: 60 + 'px' }} disabled={isDisabledFirst} variant="contained" color="success" onClick={updateGraph}>Update</Button>
        &nbsp;
                <Button color="secondary" variant="contained" style={{ width: 70 + 'px', height: 30 + 'px' }} disabled={isDisabledFirst} onClick={() => { setIsDisabledFirst(true); setWarningDailog1(true) }}>Cancel</Button>
      </div>
      <div style={{paddingTop: 20 + 'px' }}>
        <TableContainer >
          <Table stickyHeader sx={{ width: 75 + '%' }}>
            <TableHead sx={{ width: 80 + '%' }} aria-label="simple table">
              <TableRow>
                {tableHeaders.map((header) => {
                  return <TableCell key={header.id} size="small" sx={{ textAlign: "center", width: 300+'px', padding: "7px", margin: "7px", fontWeight: "bold", backgroundColor: "#6DC9C9" }}>
                    <TableSortLabel
                      active={orderBy === "header.id"}
                      direction={sortType}
                      onClick={() => { sortFunction(header.id) }}>
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                })}
                <TableCell style={{ width: 0.1 + '%', backgroundColor: "#6DC9C9", fontSize: 15 + 'px', textAlign: "center", fontWeight: "bold" }}>Update</TableCell>

                {/* <TableCell style={{ width: 18 + '%', backgroundColor: "#6DC9C9", height: 25 + 'px', fontSize: 15 + 'p , x' }}>Name</TableCell>
                <TableCell style={{ width: 25 + '%', backgroundColor: "#6DC9C9", fontSize: 15 + 'px' }} >Color</TableCell>
                <TableCell style={{ width: 18 + '%', backgroundColor: "#6DC9C9", fontSize: 15 + 'px' }}>Show In Graph</TableCell>
                <TableCell style={{ width: 0.1 + '%', backgroundColor: "#6DC9C9", fontSize: 15 + 'px' }}>Update</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {configuredGraph1.map((node, i) =>
                <TableRow>
                  <TableCell>{node.marketTypeName}</TableCell>
                  <TableCell><input type="color" value={node.colorCode} disabled></input><div style={{ float: 'right', textAlign: 'center', lineHeight: '30px', marginRight: 20 + 'px' }}>{getColorName(node.colorCode.toUpperCase())?getColorName(node.colorCode.toUpperCase()).Colorname:node.colorCode.toUpperCase()}</div></TableCell>
                  <TableCell>{node.showInGraph ? "Yes" : "No"}</TableCell>
                  <TableCell><Button style={{ width: 150 + 'px', height: 30 + 'px' }} onClick={() => { setIsDisabledFirst(false); updateGraphColor(node) }} variant="contained" color="success">Update</Button></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {warningDialog ?
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "50%",
                maxWidth: "350px",  // Set your width here
                height: "50%",
                maxHeight: "150px"
              },
            },
          }}
          open={warningDialog}>
          <DialogTitle>
            <div className="header">
              <div className="divInline">Confirmation</div>
              {/* <button className="closeButton" onClick={() => { setWarningDailog(false) }}><CloseIcon /></button> */}
            </div>
          </DialogTitle>
          <DialogContent>
            <div>{isDeleteWarning ? deleteAlert : cancelAlert}</div>
          </DialogContent>
          <DialogActions>
            <button onClick={() => { clearForm() }} style={{ float: "right", height: "20px" }} >OK</button>
            <button onClick={() => { setWarningDailog(false); setIsDisabled(false) }} style={{ float: "right", height: "20px" }}>Cancel</button>
          </DialogActions>
        </Dialog> : null}

      {warningDialog1 ?
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "50%",
                maxWidth: "350px",  // Set your width here
                height: "50%",
                maxHeight: "150px"
              },
            },
          }}
          open={warningDialog1}>
          <DialogTitle>
            <div className="header">
              <div className="divInline">Confirmation</div>
              {/* <button className="closeButton" onClick={() => { setWarningDailog1(false) }}><CloseIcon /></button> */}
            </div>
          </DialogTitle>
          <DialogContent>
            <div>{isDeleteWarning1 ? deleteAlert1 : cancelAlert1}</div>
          </DialogContent>
          <DialogActions>
            <button onClick={() => { clearGraph() }} style={{ float: "right", height: "20px" }} >OK</button>
            <button onClick={() => { setWarningDailog1(false); setIsDisabledFirst(false) }} style={{ float: "right", height: "20px" }}>Cancel</button>
          </DialogActions>
        </Dialog> : null}
    </div>
  );

}