import axios from 'axios';

const apiURL = process.env.REACT_APP_BASE_URL;

export function getHeaderColorDetails(){
    return axios.get(apiURL+"HeaderColorConfiguration/GetHeaderColorDetails");
}

export function updatHeaderColorDetails(req){
    return axios.post(apiURL+"HeaderColorConfiguration/UpdatHeaderColorDetailsForUser",req);
}