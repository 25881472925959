import React,{ useState,useEffect } from 'react';
import './alarm_acknowledgement.scss'
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton,Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useImmer } from 'use-immer';

const PaperComponent = (props) => {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  export default function AlarmAck(props){
    const { closeHandler, open, alarmData, setAcknowledgeTable,setWiggle} = props;
    const [tableData,setTableData]=useImmer(alarmData);
    useEffect(()=>{
        setTableData(alarmData)
    },[alarmData])
    
    let tableHeaders=[
        {
            tableLabel:"Status",
            id:"isAck",
        },
        {
            tableLabel:"Alarm Time",
            id:"lastUpdatedTime",
        },
        {
            tableLabel:"Market",
            id:"marketPriceType",
        },
        {
            tableLabel:"Alarm Level",
            id:"level",
        },
        {
            tableLabel:"Node Name",
            id:"aliasName",
        },
        {
            tableLabel:"Alarm Price",
            id:"alarmPrice",
        },
        {
            tableLabel:"Actual Price",
            id:"priceValue",
        }
    ]
    function acknowledeAll(){
        let tempTableData=tableData.map((data)=>{
            let k={...data};
            k.isAck=true;
            return k;
        })
        setTableData(tempTableData)
        setAcknowledgeTable(tempTableData)
        closeHandler(tempTableData) 
    }
    function acknowledgeSelected(){
        setAcknowledgeTable(tableData)
        closeHandler(tableData)
    }

    function checDisabled(){
        let k=alarmData.filter((almData)=>{
            return !almData.isAck
        })
        let l=tableData.filter((tabData)=>{
            return !tabData.isAck
        })
        return k.length===l.length;
    }

    return <Dialog sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "95%",
            maxWidth: "90%",  // Set your width here
            height: "95%",
            maxHeight:"90%"
          },
        },
      }}  
      PaperComponent={PaperComponent}
      open={open}
      >
        <DialogTitle id="draggable-dialog-title">
            <div className="header">
                <div className="divInline">Acknowledge Alarms</div>
                <button data-testid="closeIcon" className="closeButton" onClick={()=>closeHandler(alarmData)}><CloseIcon/></button>                
            </div>
        </DialogTitle>
        <DialogContent>
            <div className="AckBtnDiv">
                <div><Button disabled={checDisabled()} onClick={acknowledgeSelected} variant="contained" color="success">ACKNOWLEDGE SELECTED</Button></div>
                <div><Button onClick={acknowledeAll} variant="contained" color="success">ACKNOWLEDGE ALL</Button></div>
            </div>
            <div className="tableDiv">
                <Table stickyHeader>
                    <TableHead >
                    {tableHeaders.map((head)=>{
                        return <TableCell sx={{textAlign:"center",width: head.width,padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>{head.tableLabel}</TableCell>
                    })}                   
                    </TableHead>
                    {tableData.map((data,i)=>{
                        if(alarmData[i].isAck){
                            return null;
                        }
                        return <TableRow  key={data.aliasName+data.marketPriceType+data.lastUpdatedTime}>
                                
                                    {tableHeaders.map((head)=>{
                                        if(head.id=="isAck"){
                                            return <TableCell sx={{"text-align": "center"}}><input className="isAck" type="checkbox" value={data[head.id]} onChange={(event)=>{setTableData(draft=>{draft[i].isAck=event.target.checked})}} name="" id=""/></TableCell>
                                        }
                                        return <TableCell sx={{"text-align": "center"}} key={head.id}>{data[head.id]?data[head.id]:""}</TableCell>
                                    })}
                                </TableRow>
                    })}
                    
                </Table>
            </div>
            

        </DialogContent>
    </Dialog>
  }