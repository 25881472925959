import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Logo from '../../assets/nav-logo-sce-home.svg';
import './restricted.scss';

function Unauthorized(){
    return(
        <>
            <ErrorOutline id='icon-alert' />
            <p className="errMsg">You don't have the access to view this Page. Please contact the Admin for Access.</p>
        </>
    )
}

function UnAuthenticated(){
    return(
        <div style={{display: 'flex', width: '100vw', justifyContent: 'center'}}>
            <img alt="sce-logo" src={Logo} />
            <p className="header-title">CAISO PRICE TOOL</p>
        </div>
    )
}

function Restricted(props){
    return(
        <div className="container-noauth">
            {(props.userState==='unauthorized') && Unauthorized()}
            {(props.userState==='unauthenticated') && UnAuthenticated()}
        </div>
    )
}

export default Restricted;