import axios from 'axios';

const apiURL=process.env.REACT_APP_BASE_URL

export function getNodesDropdown(){
    return axios.post(apiURL+"NodeConfiguration/DropdownOfNodeConfiguration",{
        "dropdownName": "Node"
      });
      
}

export function getMarketType(){
    
    return axios.post(apiURL+"NodeConfiguration/DropdownOfNodeConfiguration",{
        "dropdownName": "MarketDetails"
      });
}

export function getHeaderMarketType(){
    
    return axios.post(apiURL+"NodeConfiguration/DropdownOfNodeConfiguration",{
        "dropdownName": "HeaderMarketDetails"
      });
}

export function getAllNodeConfigurationDetails(){
    
    return axios.get(apiURL+"NodeConfiguration/GetNodeConfigurationData")
}

export function deleteNode(data){
    
    return axios.post(apiURL+"NodeConfiguration/DeleteNodeConfigurationData",data)
}

export function getIndividualNodeData(data){
    
    return axios.post(apiURL+"NodeConfiguration/GetNodeConfigurationDataDetails",data)
}

export function updateUsedNodes(data){
    
    return axios.post(apiURL+"NodeConfiguration/UpdateNodeData",data)
}

export function createNode(data){
    
    return axios.post(apiURL+"NodeConfiguration/CreateNewNodeConfigurationData",data)
}

export function updateNode(data){

    return axios.post(apiURL+"NodeConfiguration/UpdateNodeConfigurationData",data)

}
export function sortObjectArray(data,orderBy,sortType){
    console.log(data,orderBy,sortType)
    return data.sort((a,b)=>{return ((a[orderBy]?a[orderBy]:"").toString().toUpperCase()>(b[orderBy]?b[orderBy]:"").toString().toUpperCase()?1:-1)*(sortType==="asc"?1:-1)})
}
export function sortObjectArrayFinal(data,orderBy,sortType,type){
    console.log(data,orderBy,sortType,type)
    if(type==="string")
        return data.sort((a,b)=>{return ((a[orderBy]?a[orderBy]:"").toString().toUpperCase()>(b[orderBy]?b[orderBy]:"").toString().toUpperCase()?1:-1)*(sortType==="asc"?1:-1)})
    if(type==="number")
        return data.sort((a,b)=>{return ((a[orderBy]?a[orderBy]:Number.NEGATIVE_INFINITY)>(b[orderBy]?b[orderBy]:Number.NEGATIVE_INFINITY)?1:-1)*(sortType==="asc"?1:-1)})
}

export function getDuration(){
    return axios.get('PriceComarisonReport/GetPriceComparisonReportDurationDropdown')
}

export function getPriceComparison(param){
    let data = {...param}
    data.market1 = data.market1==='IFM'?'DAM': data.market1;
    data.market2 = data.market2==='IFM'?'DAM': data.market2;
    return axios.post("PriceComarisonReport/GetPriceComparisonReportData", data)
}