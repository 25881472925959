import axios from 'axios';

const apiURL=process.env.REACT_APP_BASE_URL+"AlarmConfiguration/"

export function getAlarmColors(){
    return axios.get(apiURL + "GetAlarmColorDetails");
}

export function createUpdateAlarmColors(data){
    return axios.post(apiURL + "UpdateAlarmColorDetails",data);
}
