import axios from 'axios';

const apiURL=process.env.REACT_APP_BASE_URL+"TableView"

export function getTableView(data){
    return axios.post(apiURL+"/GetTableViewData",data);
}

export function checkLongDayDataAvailability(data){
    return axios.post(apiURL+"/CheckLongDayDataAvailability",data);
}