import axios from 'axios';

const apiURL=process.env.REACT_APP_BASE_URL;

export function getHeaderNodes(){
    return axios.get(apiURL + "Header/GetHeaderNodes");
}

export function getNodeValues(req){
    return axios.post(apiURL + "Header/GetNodeValues",req);
}