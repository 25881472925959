import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close';
import { useImmer } from 'use-immer';
import moment from 'moment';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import './admin_dashboard.scss';
import * as adminService from "../../Shared/Services/adminDashboard/adminDashboard.service.js"
import React,{useEffect,useState } from 'react';
import AdminFunctStatus from "../adminFunctionAppStatus/adminFunctionAppStatus.js"

let hourDropDown=[]
for(let i=1;i<=24;i++){
    hourDropDown.push({
        label:"HE"+i,
        value:i<=10?("0"+(i-1)+":00"):((i-1)+":00")
    })
}
let marketType={
    RTPD:"FMM",
    HASP:"HASP",
    DAFP:"IFM",
    RTPA:"RTM",
}

function AdminDashboard(props){
    const date = moment()
    const [postData, setPostData] = useImmer({
        notificationCode: 'RTPD',
        marketStartDate: date.format('yyyy-MM-DD'),
        marketEndDate: date.format('yyyy-MM-DD'),
        marketStartTime:"00:00",
        marketEndTime:"00:00"
    })
    const [lastTransactions,setLastTransactions]=useImmer([])
    const [statusOpen,setStatusOpen]=useState(false)
    const [statusData,setStatusData]=useState([])

    const marketChangeHandler = (e)=>{
        setPostData((draft)=>{draft.notificationCode = e.target.value})
    }
    const validations=()=>{
        let checkDate=postData.notificationCode==="DAFP"?moment(date).add(1,'d'):date
        if(!postData.marketStartDate ||  !postData.marketStartTime){
            alert("Please select valid Start Date and  Time")
            return false;            
        }
        if(!postData.marketEndDate ||  !postData.marketEndTime){
            alert("Please select valid End Date and Time")  
            return false;                                  
        }
        if((postData.marketStartDate>checkDate.format('yyyy-MM-DD') || postData.marketEndDate>checkDate.format('yyyy-MM-DD'))){
            alert("future dates are not allowed")
            return false;
        }

        if(postData.notificationCode!=="DAFP" && postData.marketStartDate+postData.marketStartTime>postData.marketEndDate+postData.marketEndTime){
            alert("End Date and Time can't be less than Start Date and Time")
            return false;                        
        }
        return true;
    }

    const submitHandler = ()=>{
        if(validations()){
            let req={
                "notificationAction":"BULK",
                "notificationMessage":"On demand Request",
                "uuId":"string",                
                notificationCode: postData.notificationCode,
                marketStartTime:moment(postData.marketStartDate+" "+postData.marketStartTime).utc().format(),
                marketEndTime:moment(postData.marketEndDate+" "+postData.marketEndTime).utc().format()
            }
            if(req.notificationCode==="DAFP"){
                req.marketEndTime=req.marketStartTime
            }
            adminService.triggerFunctionApp(req).then((result=>{
                alert("Function App Triggered Successfully")
                fetchAppDetails()
            })).catch(error=>{
                console.log(error)
            })
        }
        
    }
    function fetchAppDetails(){
        adminService.getFunctionAppDetails().then((result)=>{
            for(let i=0;i<result.data.length;i++){
                result.data[i].completed=false
                result.data[i].completedDate=null
            }
            setLastTransactions(result.data)
        }).catch(error=>{
            console.log(error)
        })
    }
    function getStatus(url){
        adminService.getStatus(url).then(result=>{
            setStatusData(result.data.output)
            setStatusOpen(true)
        }).catch(error=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        fetchAppDetails()
    },[])
    useEffect(()=>{
        function getAllStatus(){
            for(let i=0;i<lastTransactions.length;i++){
                if(lastTransactions[i].completed==false)
                    adminService.getStatus(lastTransactions[i].statusQueryGetUri).then((result)=>{
                        if(result.data.output){
                            
                            setLastTransactions(draft=>{
                                draft[i].completed=true
                                draft[i].completedDate=result.data.lastUpdatedTime
                            })
                        }
                    })
            }
        }
        let timeId=null
        if(props.open){
            getAllStatus()
                timeId=setInterval(()=>{
                getAllStatus()
            },10000)
        }
        return()=>{
            if(timeId)
                clearInterval(timeId)
        }
    },[lastTransactions,props.open])

    return(
    <Dialog open={props.open} maxWidth="lg">
        <DialogTitle> 
            <p style={{fontSize:'16px', width:'30vw'}}>Admin Dashboard</p> 
            <button style={{position:'absolute', top:'30px', right:'24px'}} className="closeButton" onClick={() => { props.close() }}><CloseIcon /></button>
        </DialogTitle>
        <DialogContent sx={{width: '80vw', height: '80vh'}}>
            <fieldset style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <div>
                    <label>Market type: </label>
                    <select data-testid="marketType" value={postData.notificationCode} onChange={(e)=>marketChangeHandler(e)}>
                        <option value="RTPD">FMM</option>
                        <option value="HASP">HASP</option>
                        <option value="DAFP">IFM</option>
                        <option value="RTPA">RTM</option>
                    </select>
                </div>
                <div>
                    <label>Start date: </label><input data-testid="dates" type='date' max={postData.notificationCode==="DAFP"?moment(date).add(1,'d').format('yyyy-MM-DD'):date.format('yyyy-MM-DD')} value={postData.marketStartDate} onChange={(e)=>{setPostData((draft)=>{draft.marketStartDate = e.target.value?moment(e.target.value).format('yyyy-MM-DD'):null})}}/>
                    <select data-testid="timeDropdown" className="timeDropdown" value={postData.marketStartTime} onChange={(e)=>{setPostData((draft)=>{draft.marketStartTime=e.target.value;})}} >
                        {hourDropDown.map(hour=>{
                            return <option key={hour.value} value={hour.value}>{hour.label}</option>
                        })}
                    </select>
                </div>
               {postData.notificationCode==="DAFP"?null:<div>
                    <label>End date: </label><input data-testid="dates" type='date'  min={postData.marketStartDate} max={date.format('yyyy-MM-DD')} value={postData.marketEndDate} onChange={(e)=>{setPostData((draft)=>{draft.marketEndDate =e.target.value?moment(e.target.value).format('yyyy-MM-DD'):null})}} />
                    {/* <input type="time" value={postData.marketEndTime} onChange={(e)=>{setPostData((draft)=>{draft.marketEndTime=e.target.value;})}} name="" id=""/>                     */}
                    <select className="timeDropdown"  value={postData.marketEndTime} onChange={(e)=>{setPostData((draft)=>{draft.marketEndTime=e.target.value;})}}>
                        {hourDropDown.map(hour=>{
                            return <option key={hour.value} value={hour.value}>{hour.label}</option>
                        })}
                    </select>
                </div>}
                <Button data-testid="submitButton" style={{backgroundColor:'#FED141', color:'black', fontSize:'12px'}} variant="contained" onClick={()=>submitHandler()}>Submit</Button>
            </fieldset>
            <br/>
            <TableContainer className="table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width:"100px",textAlign:"center",padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>Market Type</TableCell>                            
                            <TableCell sx={{width:"130px",textAlign:"center",padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>Start Date</TableCell>                            
                            <TableCell sx={{width:"130px",textAlign:"center",padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>End Date</TableCell>
                            <TableCell sx={{textAlign:"center",padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>Triggered At</TableCell>                            
                            <TableCell sx={{textAlign:"center",padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>Completed At</TableCell>                                                                                                                
                            <TableCell sx={{textAlign:"center",padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>Job Completion</TableCell>                                                                                   
                            <TableCell sx={{textAlign:"center",padding: "7px",margin:"7px",fontWeight: "bold",backgroundColor:"#6DC9C9"}}>Status Details</TableCell>                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {lastTransactions.map((trans)=>{
                                return  <TableRow key={trans.uuid}>
                                            <TableCell>{marketType[trans.notificationCode]}</TableCell>                                            
                                            <TableCell>{moment(trans.marketStartTime).format("MM-DD-yyyy HH:mm:ss")}</TableCell>
                                            <TableCell>{moment(trans.marketEndTime).format("MM-DD-yyyy HH:mm:ss")}</TableCell>
                                            <TableCell>{moment(trans.createdon+"z").format("MM-DD-yyyy HH:mm:ss")}</TableCell>                                                                                        
                                            <TableCell>{trans.completed?moment(trans.completedDate).format("MM-DD-yyyy HH:mm:ss"):"In Progress..."}</TableCell>                                            
                                            <TableCell>{trans.completed?"completed":"In Progress..."}</TableCell>                                            
                                            <TableCell><Button variant="contained" color="success" disabled={!trans.completed} onClick={()=>getStatus(trans.statusQueryGetUri)}>Get Status</Button></TableCell>
                                        </TableRow>
                            })}                        
                    </TableBody>
                </Table>
            </TableContainer>
            {statusOpen?<AdminFunctStatus
            open={statusOpen}
            data={statusData}
            close={()=>{setStatusOpen(false)}}/>:null}
        </DialogContent>
    </Dialog>
    )
}

export default AdminDashboard