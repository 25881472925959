import Logo from '../../assets/nav-logo-sce-home.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './home.component.scss';
import Header from '../header/header';
import SettingsIcon from '@mui/icons-material/Settings';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import RefreshIcon from '@mui/icons-material/Refresh';
import React,{ useEffect, useState } from 'react';
import Settings from '../Settings/settings.js';
import Tableview from '../tableview/tableview';
import alarmAudio from '../../assets/notify.wav';
import AdminDashboard from '../admin_dashboard/admin_dashboard';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PriceComparison from '../price_comparison/price_comparison.js';

import * as alarmConfigService from '../../Shared/Services/alarmConfiguration/alarmConfigurati.service.js';
import { useImmer } from 'use-immer';
import * as alarmColorService from '../../Shared/Services/alarm_color/alarm_color.service.js';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import moment from 'moment/moment';
import AlarmAck from '../alarm_acknowledgement/alarm_acknowledgement.js'
import Graph from '../graph/graph';
import Tooltip from '@mui/material/Tooltip';

function HomeComponent(props) {
    let audioController=new Audio(alarmAudio);
    audioController.loop=true;
    const [settingsOpen,setOpen]=useState(false);
    const [graphOpen,setGraphOpen]=useState(false);
    const [rtmGraphOpen,setRtmGraphOpen]=useState(false);
    const [settingsCloseFlag,setSettingsCloseFlag]=useState(0);
    const [ackOpen,setAckOpen]=useState(false);    
    const [acknowledgeTable,setAcknowledgeTable]=useImmer([])
    const [playAudio,setPlayAudio]=useState(false)
    const [wiggle,setWiggle]=useState(false)
    const [applicationOpenTime,setApplicationOpenTime]=useState(moment(new Date()).format());
    const [lastUpdatedTime,setLastUpdatedTime]=useState(moment(new Date()).format("MM/DD/yyyy HH:mm:ss"));
    const [muted,setMuted]=useState(false);
    const [alarmColorData,setAlarmColorData]=useState({})
    const [adminDash, setAdminDash] = useState(false);
    let userId={usrId:JSON.parse(localStorage.getItem("user")).username}  

    function openSettings(val){
        setOpen(val)
    }
    function closeSettings(){
        setOpen(false)
    }
    function reload(childData){
        setSettingsCloseFlag(childData);
    }
    function getAlarmType(instantValue,alarmUt1,alarmUt2,alarmLt1,alarmLt2){
        let tableLabel=[
            {"colorKey":"alarmPos2Color","level":"Alarm+2",alarmPrice:alarmUt2},
            {"colorKey":"alarmPos1Color","level":"Alarm+1",alarmPrice:alarmUt1},
            {"colorKey":"alarmNeg1Color","level":"Alarm-1",alarmPrice:alarmLt1},
            {"colorKey":"alarmNeg2Color","level":"Alarm-2",alarmPrice:alarmLt2},
        ]
        //Dont change the order it will break the logic 
        if(instantValue<alarmLt2){
            return tableLabel[3]
        }
        if(instantValue<alarmLt1){
            return tableLabel[2]
        }
        if(instantValue>alarmUt2){
            return tableLabel[0]
        }
        if(instantValue>alarmUt1){
            return tableLabel[1]
        }
        return null
    }
     
    useEffect(()=>{
        if(playAudio && !muted){
            let existsAck=acknowledgeTable.find((almCheckData)=>{
                return !almCheckData.isAck
            })
            if(existsAck){
                audioController.play().then().catch((error)=>{
                    console.log("please add web into Chrome settings to enable audio auto play")
                })
            }
        }
        else{
            audioController.pause()
        }
        return ()=>{
            audioController.pause()
        }
    },[playAudio,muted])

    useEffect( ()=>{
        async function trigger(){
            let alarmConfigResult=await alarmConfigService.getAlarmConfig(userId)
            let alarmColorResult=await alarmColorService.getAlarmColors()
            setAlarmColorData(alarmColorResult.data[0])
            let alarmConfigData=alarmConfigResult.data;
            for(let i=0;i<alarmConfigData.length;i++){
                alarmConfigService.getNodeName({
                    "aliasName": alarmConfigData[i].alrmUsrPrfLocNam
                    }).then(res=>{
                        let postData={
                        "nodeLocName": res.data[0].nodeName,
                        "marketPriceType": alarmConfigData[i].alrmUsrPrfMktNam
                        }
                        alarmConfigService.getAlarmAcknowledgement(postData).then(response=>{
                        response.data[0]["aliasName"]=alarmConfigData[i].alrmUsrPrfLocNam
                        let instantValue=response.data[0].priceValue
                        let alarmUt1=alarmConfigData[i].alrmUsrPrfUThrshld1Num
                        let alarmUt2=alarmConfigData[i].alrmUsrPrfUThrshld2Num
                        let alarmLt1=alarmConfigData[i].alrmUsrPrfLThrshld1Num
                        let alarmLt2=alarmConfigData[i].alrmUsrPrfLThrshld2Num
                        let alarmColorDetails=getAlarmType(instantValue,alarmUt1,alarmUt2,alarmLt1,alarmLt2)
                        if(alarmColorDetails){
                            let tempAck={...response.data[0],...alarmColorDetails,color:(alarmColorResult.data[0])[alarmColorDetails.colorKey],isAck:false,lastUpdatedTime:lastUpdatedTime}
                            
                            let findExisting=acknowledgeTable.find((almCheckData)=>{
                                // console.log(moment(tempAck.createdDt).isAfter(applicationOpenTime))
                                // console.log(tempAck,almCheckData,moment(tempAck.createdDt).format("MM/DD/yyyy HH:mm:ss"),applicationOpenTime)
                                return almCheckData.nodeLocName==tempAck.nodeLocName && almCheckData.marketPriceType==tempAck.marketPriceType && almCheckData.intervalNumber==tempAck.intervalNumber && almCheckData.todayHoursCount==tempAck.todayHoursCount && almCheckData.priceValue==tempAck.priceValue
                            })
                            console.log(findExisting)
                            if(!findExisting && moment(tempAck.createdDt).isAfter(applicationOpenTime) && moment(tempAck.createdDt).isAfter(alarmConfigData[i].modifiedDt)){
                                setAcknowledgeTable((draft)=>{
                                    return draft.concat(tempAck)
                                })
                                setPlayAudio(true)
                                setWiggle(true)
                            }
                            
                        }
                        })
                    })
            }
        }
        trigger()
        // let timeId=setInterval(trigger,120000)
        return ()=>{
            // clearInterval(timeId)
        }
    },[lastUpdatedTime])
    function muteAudio(){
        setMuted(!muted)
        // setPlayAudio(false)
    }
    let shake={
        animation: "shake 1s infinite"
    }
    
    const [marketTypeOpen,setOpen2]=useState(false);
    const [marketHeader,setOpen1] = useState(false);

    const closeMarketType=()=>{
        setOpen2(false)
    }

    const closeMarketHeader=()=>{
        setOpen1(false)
    }

    function closeGraph(){
        setGraphOpen(false);
    }
    function ackCloseHandler(tempTableData){
        setAckOpen(false);
        let existsAck=tempTableData.find((almCheckData)=>{
            return !almCheckData.isAck
        })
        if(!existsAck){
            setWiggle(false);
            setPlayAudio(false);
        }
    }
    function handleRtmGraph(flag){
        setRtmGraphOpen(flag);
    }

    return (
        <div>
            <header>
                <div className='header-lft'>
                    <img alt="sce-logo" src={Logo} />
                    <p className="header-title">CAISO PRICE TOOL</p>
                </div>
                <div className="arrow-right"></div>
                <div className='options'>
                    <Tooltip title="Refresh Dashboard"><button onClick={()=>{ setSettingsCloseFlag((data)=>{return data+1})}}><RefreshIcon fontSize='large'/></button></Tooltip>
                    <Tooltip title={muted?"Unmute Alarm":"Mute Alarm"}><button onClick={muteAudio}>{!muted?<VolumeUpIcon fontSize='large'/>:<VolumeOffIcon fontSize='large'/>}</button></Tooltip>
                    <Tooltip title="Alarm Acknowledgement"><button style={wiggle?shake:{}} onClick={()=>{setAckOpen(true)}}><AccessAlarmsIcon fontSize='large'/></button></Tooltip>
                    <Tooltip title="Application Configuration"><button data-testid="settingsIcon" onClick={(event)=>{openSettings(true)}}><SettingsIcon fontSize='large'/></button></Tooltip>
                    <Tooltip title="Price Comparison Report"><button onClick={(event)=>{setOpen2(true)}}><PriceChangeIcon fontSize="large"/></button> </Tooltip>
                    <PriceComparison className="dialog"
                    open={marketTypeOpen} 
                    closeHandler={closeMarketType} /> 
                    <Tooltip title="Graph"><button onClick={()=>{setGraphOpen(true); setRtmGraphOpen(true);}}><LeaderboardIcon fontSize='large'/></button></Tooltip>
                    <Tooltip title="Admin Dashboard"><button onClick={()=>{setAdminDash(true)}}><AssignmentIndIcon fontSize='large'/></button></Tooltip>
                </div>
                
                <Settings className="dailog" 
                open={settingsOpen}
                closeHandler={closeSettings}
                reloadFunction={reload}
                settingsCloseFlag = {settingsCloseFlag}/>

                <AlarmAck className="dailog" 
                open={ackOpen}
                closeHandler={ackCloseHandler}
                setAcknowledgeTable={setAcknowledgeTable}
                alarmColors={alarmColorData}
                setWiggle={setWiggle}
                setPlayAudio={setPlayAudio}
                alarmData={acknowledgeTable}/>

                <Graph className="dailog"
                open={graphOpen}
                openRtmGraph={rtmGraphOpen}
                closeGraph={closeGraph}
                nodeName={"SP15"}
                handleRtmGraph={handleRtmGraph}
                settingsCloseFlag={settingsCloseFlag}/>
                <AdminDashboard className="dialog" open={adminDash} close={setAdminDash}/>
            </header>
            
            <Header alarmColors={alarmColorData} setLastUpdatedTime={setLastUpdatedTime} alarmData={acknowledgeTable} settingsCloseFlag={settingsCloseFlag} />
            <Tableview alarmColors={alarmColorData} lastUpdatedTime={lastUpdatedTime} alarmData={acknowledgeTable} settingsCloseFlag={settingsCloseFlag}/>
        </div>
    )
}

export default HomeComponent;