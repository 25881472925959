import '../price_comparison/price_comparison.scss';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { getAllNodeConfigurationDetails, getMarketType, getDuration, getPriceComparison } from '../../Shared/Services/nodeConfiguration/node.configuration.service.js'
import { useImmer } from 'use-immer';
import moment from 'moment';

export default function PriceComparison(props){
  const { closeHandler, open } = props;
  const [options, setOptions] = useImmer({
    nodes: [],
    markets: [],
    duration: []
  })
  const [postData, setPostData] = useImmer({})
  const [avgPrice, setAvgPrice] = useState({})

  const reset = () =>{
    setAvgPrice({})
    setPostData({ 
      nodeName1: "",
      nodeName2: "",
      market1: "",
      market2: "",
      reportDuration: "",
      startDate: moment().format('yyyy-MM-DD'),
      startHour: 1,
      endDate: moment().format('yyyy-MM-DD'),
      endHour: 1,
    })
  }

  useEffect(()=>{
    reset()
    if(!props.open) return
    getAllNodeConfigurationDetails().then(res => setOptions(draft => { draft.nodes = res.data }))
    getMarketType().then(res => setOptions(draft => { draft.markets = res.data }))
    getDuration().then(res => {
      setOptions(draft => { draft.duration = res.data })
      setPostData(draft => { draft.reportDuration = res.data[0].durationType })
    })
  }, [props.open]) // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(()=>{
    if(avgPrice.node1==="" || avgPrice.node2==="") alert("There is no price value for the searched criteria")
  }, [avgPrice])

  function setPostDataHandler(e){
    setPostData(draft=>{
      draft[e.target.id] = (e.target.id==='startHour' || e.target.id==='endHour')? parseInt(e.target.value): e.target.value;
    })
  }

  function getHours(){
    let tmp = []
    for(let i=1; i<=24; i++){
      tmp.push(<option key={i} value={i}>HE {i}</option>)
    }
    return tmp
  }

  function onCalculate(){
    let msg;
    if(postData.nodeName1==="") msg="Please select the node"
    else if(postData.market1==="") msg="Please select the market"
    else if(postData.nodeName2==="") msg="Please select the node to compare"
    else if(postData.market2==="") msg="Please select the market to compare"
    else if(postData.nodeName1===postData.nodeName2 && postData.market1===postData.market2) msg="Please select either a different Node or different market to compare"
    else if (moment(postData.startDate)>moment(postData.endDate)) msg="Selected start date should be less than the selected end date"
    else if (postData.startDate===postData.endDate && postData.endHour<postData.startHour) msg="Start hour should be less than End hour for selected dates."
    if(msg) alert(msg)

    else getPriceComparison(postData).then(res => {
      res = res.data.priceComparisonReportsData
      setAvgPrice({
        node1: res.length===2? res[0].averagePrice: "",
        node2: res.length===2? res[1].averagePrice: "",
      })
    })
  }

  return (
  <>
    <Dialog sx={{
       "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "60%",
            maxWidth: "100%",  // Set your width here
            height: "80%",
            maxHeight: "100%"
        },
    },
    }}
    open={open}>
     <DialogTitle>
        <div className="header">
            <div className="divInline">Price Comparison Report</div>
            <button className="closeButton" onClick={() => { closeHandler() }}><CloseIcon /></button>
        </div>
    </DialogTitle>

    <div style={{display:"flex"}}>
      <h1 style={{marginLeft: 50 + 'px' ,fontSize:15+'px'}}>Node Name : </h1>
      <select id="nodeName1" style={{width:150 + 'px' , height:25 + 'px' , marginLeft:40 + 'px'}} onChange={setPostDataHandler}>
        <option value="">--Select--</option>
        {
          options.nodes.map(opt=><option key={opt.nodename+'1'} value={opt.nodename}>{opt.aliasname}</option>)
        }
      </select>
      <select id="nodeName2" style={{width:150 + 'px' , height:25 + 'px' , marginLeft:150 + 'px'}} onChange={setPostDataHandler}>
        <option value="">--Select--</option>
        {
          options.nodes.map(opt=><option key={opt.nodename+'2'} value={opt.nodename}>{opt.aliasname}</option>)
        }
      </select>
    </div>
    <div style={{display:"flex" , marginTop: 20 + 'px'}} onChange={setPostDataHandler}>
      <h1 style={{marginLeft: 50 + 'px' , fontSize:15+'px'}}>Market : </h1>
      <select id="market1" style={{width:150 + 'px' , height:25 + 'px' , marginLeft:65 + 'px'}}>
        <option value="">--Select--</option>
        {
          options.markets.map(opt=><option key={opt.descprition+'1'} value={opt.descprition}>{opt.descprition}</option>)
        }
      </select>
      <select id="market2" style={{width:150 + 'px' , height:25 + 'px' , marginLeft:150 + 'px'}} onChange={setPostDataHandler}>
        <option value="">--Select--</option>
        {
          options.markets.map(opt=><option key={opt.descprition+'2'} value={opt.descprition}>{opt.descprition}</option>)
        }
      </select>
    </div>
    <div style={{display:"flex",  marginTop: 20 + 'px'}} >
      <h1 style={{marginLeft: 50 + 'px', fontSize:15+'px'}}>Duration : </h1>
      <select id="reportDuration" style={{width:150 + 'px' , height:25 + 'px' , marginLeft:54 + 'px'}} onChange={setPostDataHandler}>
        {
          options.duration.map(opt=><option key={opt.durationType} value={opt.durationType}>{opt.durationType}</option>)
        }
      </select>
    </div>
    <div style={{display:"flex",  marginTop: 20 + 'px'}}>
      <h1 style={{marginLeft: 50 + 'px', fontSize:15+'px'}}>Start Date / Hour: </h1>
      <input type="date" id="startDate" value={postData.startDate} onChange={setPostDataHandler} style={{width:150 + 'px' , height:25 + 'px', marginLeft:5 + 'px'}}/>
      <select id="startHour" style={{width:80 + 'px' , height:25 + 'px' , marginLeft:50 + 'px'}} onChange={setPostDataHandler}>
        { getHours() }
      </select>
    </div>
    <div style={{display:"flex",  marginTop: 20 + 'px'}}>
      <h1 style={{marginLeft: 50 + 'px', fontSize:15+'px'}}>End Date / Hour: </h1>
      <input type="date" id="endDate" value={postData.endDate} onChange={setPostDataHandler} style={{width:150 + 'px' , height:25 + 'px', marginLeft:10 + 'px'}}/>
      <select id="endHour" style={{width:80 + 'px' , height:25 + 'px' , marginLeft:50 + 'px'}} onChange={setPostDataHandler}>
        { getHours() }
      </select>
    </div>
    <div style={{display:"flex",  marginTop: 20 + 'px'}}>
      <h1 style={{marginLeft: 50 + 'px', fontSize:15+'px'}}>Average Price ($): </h1>
      <input type="number" value={avgPrice.node1} style={{width:145 + 'px' , height:25 + 'px', marginLeft:10 + 'px' , backgroundColor:"lightgrey"}} disabled/>
      <input type="number" value={avgPrice.node2} style={{width:150 + 'px' , height:25 + 'px', marginLeft:140 + 'px', backgroundColor:"lightgrey"}} disabled/>
    </div>
    <div style={{margin: '20px 0' , textAlign:"center"}}>
      <button style={{backgroundColor:"#6DC9C9", fontSize:15+'px' , width:200 + 'px'}} onClick={()=>onCalculate()}>Calculate</button>
    </div>
    </Dialog>
  </>
  )}