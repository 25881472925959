import axios from 'axios';

const apiURL=process.env.REACT_APP_BASE_URL+"AlarmConfiguration/"

export function addAlarmConfig(data){
    return axios.post(apiURL + "AddAlarmConfigurationDataForUser",data);
}

export function getAlarmConfig(data){
    return axios.post(apiURL + "GetAlarmDetailsForUserFromUserId",data);
}
export function getIndividualAlarmConfig(data){
    return axios.post(apiURL + "GetAlarmDetailsForUserFromAlarmId",data);
}

export function deleteAlarmConfig(data){
    return axios.post(apiURL + "DeleteAlarmDetailsForUser",data);
}
export function updateAlarmConfig(data){
    return axios.post(apiURL + "UpdateAlarmConfigurationDataForUser",data);
}

export function getNodeName(data){
    return axios.post(apiURL + "GetNodeNameFromNodeAliasName",data);    
}
export function getAlarmAcknowledgement(data){
    return axios.post(apiURL + "GetAlarmAcknowledgementDetails",data);    
}