import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import './header.scss';
import * as headerService from '../../Shared/Services/headerService/header.service'
import { useImmer } from 'use-immer';
import * as headerColorService from '../../Shared/Services/headerColorService/headerColor.service'
import { formatNegativeValues } from '../../utilities/formatMethods.js'

export default function Header(props) {
    const { settingsCloseFlag,setLastUpdatedTime,alarmData,alarmColors } = props;
    const [currentTime, setCurrentTime] = useState(new Date());
    const [lPDTime, setLPDTime] = useState(new Date());
    const [refresh, setRefresh] = useState(new Date());
    const [headerTable, setHeaderTable] = useImmer([]);
    const [colorObj, setColorObj] = useImmer({
        headerRtmColor: "#FFFFFF",
        headerHaspColor: "#FFFFFF",
        headerIfmColor: "#FFFFFF",
        headerFmmColor: "#FFFFFF",
        textRtmColor: "#000000",
        textHaspColor: "#000000",
        textIfmColor: "#000000",
        textFmmColor: "#000000",
    });
   

    useEffect(() => {
        let timeId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => { clearInterval(timeId) }
    }, [currentTime])

    useEffect(() => {
        headerService.getHeaderNodes().then((response) => {
            setHeaderTable(response.data);
            response.data.map((e, i) => {
                headerService.getNodeValues({ nodename: e.nodename, headermarkettype: e.headermarkettype }).then((res) => {
                    setHeaderTable((darft) => { darft[i].value = (e.headermarkettype !== "HASP" ? res.data.value1[0].newvalue : res.data.avgValue) });
                });
            });
        });
        setLPDTime(new Date())
        setLastUpdatedTime(moment(new Date()).format("MM/DD/yyyy HH:mm:ss"))
        headerColorService.getHeaderColorDetails().then((response) => {
            setColorObj(response.data[0]);
        });
    }, [settingsCloseFlag]);

    useEffect(() => {
        let timeId = setInterval(() => {
            headerService.getHeaderNodes().then((response) => {
                setHeaderTable(response.data);
                response.data.map((e, i) => {
                    headerService.getNodeValues({ nodename: e.nodename, headermarkettype: e.headermarkettype }).then((res) => {
                        setHeaderTable((darft) => { darft[i].value = (e.headermarkettype !== "HASP" ? res.data.value1[0].newvalue : res.data.avgValue) });
                    });
                });
            });
            setLPDTime(new Date())
            setLastUpdatedTime(moment(new Date()).format("MM/DD/yyyy HH:mm:ss"))
        }, 120000);
        return () => { clearInterval(timeId) }
    }, [headerTable]);

    return (
        <>
            <div className='time_sec'>
                <p>CURRENT TIME: {moment(currentTime).format("MM/DD/yyyy HH:mm:ss")}</p>
                <p>LAST PRICE DOWNLOAD: {moment(lPDTime).format("MM/DD/yyyy HH:mm:ss")}</p>
            </div>

            <div className='parent_div'>
                {
                    headerTable.map((e, i) => {
                        let color=alarmData.find((almdata)=>{
                            return !almdata.isAck && e.aliasname==almdata.aliasName && e.headermarkettype==almdata.marketPriceType
                        })
                        return <div  className='child_div' style={i == 0 ? { marginLeft: `0` } : null}>
                            <div className='top_sec' style={{ backgroundColor: `${colorObj[`header${e.headermarkettype.slice(0, 1)}${e.headermarkettype.slice(1).toLowerCase()}Color`]}`, color: `${colorObj[`text${e.headermarkettype.slice(0, 1)}${e.headermarkettype.slice(1).toLowerCase()}Color`]}` }} >{e.headermarkettype}</div>
                            <div style={color?{backgroundColor:alarmColors[color.colorKey]}:{}} className='mid_sec'>{e.aliasname}</div>
                            <div style={color?{backgroundColor:alarmColors[color.colorKey]}:{}} className='bot_sec'> {(e.value === null || e.value === undefined) ? e.value : formatNegativeValues(e.value.toFixed(2))} </div>
                        </div>
                    })
                }
            </div>
        </>
    )
}
